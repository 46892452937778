/* eslint-disable prettier/prettier */
const spriteData = {
  1: [0, 600],
  2: [600, 520],
  3: [1120, 520],
  4: [1640, 760],
  5: [2400, 580],
  6: [2980, 680],
  7: [3660, 630],
  8: [4290, 630],
  9: [4920, 680],
  10: [5600, 650],
  11: [6250, 650],
  12: [6900, 600],
  13: [7500, 810],
  14: [8310, 890],
  15: [9200, 940],
  16: [10140, 910],
  17: [11050, 860],
  18: [11910, 860],
  19: [12770, 810],
  20: [13580, 990],
  21: [14570, 990],
  22: [15560, 1000],
  23: [16560, 970],
  24: [17530, 1100],
  25: [18630, 940],
  26: [19570, 1100],
  27: [20670, 930],
  28: [21600, 1020],
  29: [22620, 910],
  30: [23530, 700],
  31: [24230, 1020],
  32: [25250, 970],
  33: [26220, 1000],
  34: [27220, 1100],
  35: [28320, 990],
  36: [29310, 1100],
  37: [30410, 970],
  38: [31380, 1040],
  39: [32420, 1020],
  40: [33440, 760],
  41: [34200, 990],
  42: [35190, 910],
  43: [36100, 1020],
  44: [37120, 1150],
  45: [38270, 990],
  46: [39260, 1120],
  47: [40380, 1020],
  48: [41400, 1150],
  49: [42550, 1040],
  50: [43590, 840],
  51: [44430, 1250],
  52: [45680, 1050],
  53: [46730, 1120],
  54: [47850, 1230],
  55: [49080, 1100],
  56: [50180, 1280],
  57: [51460, 1070],
  58: [52530, 1200],
  59: [53730, 1120],
  60: [54850, 860],
  61: [55710, 1120],
  62: [56830, 1020],
  63: [57850, 1050],
  64: [58900, 1180],
  65: [60080, 1070],
  66: [61150, 1150],
  67: [62300, 1050],
  68: [63350, 1180],
  69: [64530, 1120],
  70: [65650, 780],
  71: [66430, 1170],
  72: [67600, 1050],
  73: [68650, 1050],
  74: [69700, 1220],
  75: [70920, 1100],
  76: [72020, 1180],
  77: [73020, 1070],
  78: [74270, 1120],
  79: [75390, 1100],
  80: [76490, 700],
  81: [77190, 1170],
  82: [78360, 890],
  83: [79250, 1020],
  84: [80270, 1100],
  85: [81370, 1020],
  86: [82390, 1150],
  87: [83540, 1020],
  88: [84560, 1180],
  89: [85740, 1090],
  90: [86830, 810],
  bingo: [87640, 810],
  startsoon30: [88450, 3530],
  bingostart: [91980, 2530],
  bingoend: [94510, 4100],
};

export default spriteData;
