import React, { useState } from 'react';
import { func, bool } from 'prop-types';

import useUserStore from 'stores/user';
import usePurchaseStore from 'stores/purchase';
import useCurrentGameStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import useApiErrorStore from 'stores/api-error';
import shallow from 'zustand/shallow';
import api from 'utils/api';

import ConfirmPurchase from './ConfirmPurchase/ConfirmPurchase';
import PurchaseReceipt from './PurchaseReceipt/PurchaseReceipt';
import IncuffientFunds from './IncuffientFunds/IncuffientFunds';

const CONFIRM_PURCHASE = 'CONFIRM_PURCHASE';
const PURCHASE_RECEIPT = 'PURCHASE_RECEIPT';
const INCUFFIENT_FUNDS = 'INCUFFIENT_FUNDS';

const selectedCardIdSelector = (state) => [
  state.selectedCardIds,
  state.resetSelectedCardIds,
];
const bingoCardsListSelector = (state) => [
  state.bingoCardsList,
  state.setBingoCardsList,
];
const userSelector = (state) => [state.user, state.setUser];
const currentGameSelector = (state) => state.currentGame;
const prebuyTicketAmountSelector = (state) => [
  state.prebuyTicketAmount,
  state.updatePrebuyTicketAmount,
];
const upcomingGameSelector = (state) => state.nextGame;
const setApiError = (state) => state.setErrorMessage;

const PurchaseModal = ({ onClose, isPrebuy }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentView, setCurrentView] = useState(CONFIRM_PURCHASE);
  const [selectedCardIds, resetSelectedCardIds] = usePurchaseStore(
    selectedCardIdSelector,
    shallow
  );
  const [bingoCardsList, setBingoCardsList] = usePurchaseStore(
    bingoCardsListSelector,
    shallow
  );
  const currentGame = useCurrentGameStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(upcomingGameSelector);
  const [prebuyTicketAmount, setPrebuyTicketAmount] = usePurchaseStore(
    prebuyTicketAmountSelector,
    shallow
  );
  const cardPrice = isPrebuy ? nextGame.cardPrice : currentGame.cardPrice;
  const cardsCount = isPrebuy ? prebuyTicketAmount : selectedCardIds.length;
  const totalPrice = cardPrice * cardsCount;
  const [user, setUser] = useUserStore(userSelector, shallow);
  const setApiErrorMessage = useApiErrorStore(setApiError);

  const onClickClose = () => {
    if (!isLoading) {
      if (currentView === PURCHASE_RECEIPT) {
        if (isPrebuy) {
          setPrebuyTicketAmount(0);
        } else {
          resetSelectedCardIds();
          setBingoCardsList([]);
        }
      }
      setCurrentView(CONFIRM_PURCHASE);

      onClose();
    }
  };

  const onClickConfirm = async () => {
    setIsLoading(true);

    if (isPrebuy) {
      const data = {
        bingoRoundId: nextGame.bingoRoundId,
        numberOfCards: cardsCount,
      };
      await api
        .post('​/api/Bingo/prebuy', data)
        .then(() => {
          user.callback_purchase({
            gameId: String(nextGame.bingoRoundId),
            name: nextGame.name,
            ticketPrice: cardPrice,
          });
          setUser({ updateBalance: true });
          setIsLoading(false);
          setCurrentView(PURCHASE_RECEIPT);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.data === 'Not_Enough_Funds_To_Pay') {
            setCurrentView(INCUFFIENT_FUNDS);
          } else {
            onClose();
            setApiErrorMessage(10);
          }
        });
    } else {
      const cardsToPurchase = [];
      await selectedCardIds.forEach((cardId) => {
        cardsToPurchase.push(
          bingoCardsList.find((element) => element.checksum === cardId)
        );
      });

      const data = {
        bingoRoundId: currentGame.bingoRoundId,
        cardsToBuy: cardsToPurchase,
      };

      await api
        .post('/api/Bingo', data)
        .then(() => {
          user.callback_purchase({
            gameId: String(currentGame.bingoRoundId),
            name: currentGame.name,
            ticketPrice: cardPrice,
          });
          setUser({ updateBalance: true });
          setIsLoading(false);
          setCurrentView(PURCHASE_RECEIPT);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.data === 'Not_Enough_Funds_To_Pay') {
            setCurrentView(INCUFFIENT_FUNDS);
          } else {
            onClose();
            setApiErrorMessage(10);
          }
        });
    }
  };

  const onClickLoadMoney = () => {
    onClickClose();
    user.callback_deposit();
  };

  switch (currentView) {
    case PURCHASE_RECEIPT:
      return (
        <PurchaseReceipt
          onClose={onClickClose}
          isLoading={isLoading}
          cardPrice={cardPrice}
          cardsCount={cardsCount}
          totalPrice={totalPrice}
          username={user.nickname}
        />
      );

    case INCUFFIENT_FUNDS:
      return (
        <IncuffientFunds
          onClose={onClickClose}
          onClickLoadMoney={onClickLoadMoney}
          isLoading={isLoading}
        />
      );

    default:
      return (
        <ConfirmPurchase
          onClose={onClickClose}
          onClickConfirm={onClickConfirm}
          isLoading={isLoading}
          cardPrice={cardPrice}
          cardsCount={cardsCount}
          totalPrice={totalPrice}
        />
      );
  }
};

PurchaseModal.propTypes = {
  onClose: func,
  isPrebuy: bool.isRequired,
};

PurchaseModal.defaultProps = {
  onClose: () => {},
};

export default PurchaseModal;
