import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';
import useMenuStore from 'stores/menu';

export const MOBILE = 'mobile';
// export const MOBILE_LANDSCAPE = 'mobile-landscape';
export const TABLET_PORTRAIT = 'tablet-portrait';
// export const TABLET_LANDSCAPE = 'tablet-landscape';
export const DESKTOP = 'desktop';
// export const DESKTOP_LARGE = 'desktop-large';

const smallMobile = 374;
// const mobileLandscape = 667;
const tabletPortrait = 768;
// const tabletLandscape = 1024;
const desktop = 1280;
// const desktopLarge = 1400;

export const getBreakpointsConfig = (width) => {
  if (width < tabletPortrait) {
    return MOBILE;
  }
  // if (width >= mobileLandscape && width < tabletPortrait) {
  //   return MOBILE_LANDSCAPE;
  // }
  if (width >= tabletPortrait && width < desktop) {
    return TABLET_PORTRAIT;
  }
  // if (width >= tabletLandscape && width < desktop) {
  //   return TABLET_LANDSCAPE;
  // }
  if (width >= desktop) {
    return DESKTOP;
  }
  // if (width >= desktopLarge) {
  //   return DESKTOP_LARGE;
  // }
};

const useBreakpoint = () => {
  const setIsExpanded = useMenuStore((state) => state.setIsMenuExpanded);
  const [breakpoint, setBreakpoint] = useState(() =>
    getBreakpointsConfig(window.innerWidth)
  );

  useEffect(() => {
    const onResize = throttle(() => {
      if (
        window.innerWidth <= smallMobile ||
        window.innerWidth >= tabletPortrait
      ) {
        setIsExpanded(false);
      }
      setBreakpoint(getBreakpointsConfig(window.innerWidth));
    }, 500);
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return breakpoint;
};

export default useBreakpoint;
