import React, { useRef } from 'react';
import { func } from 'prop-types';
import clsx from 'clsx';
import { useClickAway } from 'react-use';

import useBreakpoint, { MOBILE, TABLET_PORTRAIT } from 'hooks/useBreakpoint';
import text from 'polyglot/polyglot';

import usePrizePlanStore from 'stores/prizeplan-modal';
import { useChatStore } from 'stores/chat';
import useUserStore from 'stores/user';
import useMenuStore from 'stores/menu';
import useGameStore, { GAME_REPLAY } from 'stores/game';
import shallow from 'zustand/shallow';

import Button from 'components/Button/Button';
import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';
import UserProfileContainer from 'components/TopbarPanel/UserProfileContainer/UserProfileContainer';
import SettingButtons from 'components/TopbarPanel/SettingButtons/SettingButtons';
import LayoutButtons from 'components/TopbarPanel/LayoutButtons/LayoutButtons';

import ReturnArrow from 'assets/svg/return-arrow.svg';
import ArrowHead from 'assets/svg/arrowhead.svg';
import Hamburger from 'assets/svg/hamburger-icon.svg';
import logo from 'assets/theme/svg/logo-mobile-86x28.svg';

import styles from './MobileView.module.scss';

const isChatExpandedSelector = (state) => [
  state.isChatExpanded,
  state.setIsChatExpanded,
];
const menuOpenSelector = (state) => [state.isMenuOpen, state.setIsMenuOpen];
const userLinkSelector = (state) => state.user.returnButtonURL;
const gameStateSelector = (state) => state.gameState;

const MobileView = ({ modalVisibility }) => {
  const setModalVisibility = usePrizePlanStore(
    (state) => state.setModalVisibility
  );
  const [isChatExpanded, setIsChatExpanded] = useChatStore(
    isChatExpandedSelector,
    shallow
  );
  const [isSideMenuActive, setSideMenuActive] = useMenuStore(
    menuOpenSelector,
    shallow
  );
  const link = useUserStore(userLinkSelector);
  const breakpoint = useBreakpoint();
  const innerRef = useRef(null);

  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;

  const openSidemenu = () => {
    setSideMenuActive(true);
  };
  const closeSideMenu = () => {
    setSideMenuActive(false);
  };

  useClickAway(innerRef, closeSideMenu);

  return (
    <>
      <div
        className={clsx(styles.wrapper, {
          [styles.isHidden]: isSideMenuActive || isChatExpanded,
        })}
      >
        {breakpoint === MOBILE ? (
          <a href={link}>
            <Button isIcon className={styles.returnButton} onClick={() => {}}>
              <ReturnArrow />
            </Button>
          </a>
        ) : (
          <div className={styles.tabletSettingsContainer}>
            <SettingButtons isWhite />
          </div>
        )}
        {breakpoint === TABLET_PORTRAIT && <LayoutButtons />}
        <Button isIcon className={styles.menuButton} onClick={openSidemenu}>
          <Hamburger />
        </Button>
      </div>
      <div
        className={clsx(styles.menuWrapper, {
          [styles.active]: isSideMenuActive,
        })}
      >
        <div
          ref={innerRef}
          className={clsx(styles.sideMenu, {
            [styles.active]: isSideMenuActive,
          })}
        >
          <img src={logo} alt="Miljonlotteriet" className={styles.logo} />
          {!isReplay && <UpcomingGameBanner />}
          <ul className={styles.menu}>
            {!isReplay && (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    setModalVisibility();
                    closeSideMenu();
                  }}
                >
                  {text.t('topPanel.listItem1')}
                </button>
              </li>
            )}
            <li>
              <button
                type="button"
                onClick={() => {
                  closeSideMenu();
                  modalVisibility();
                }}
              >
                {text.t('topPanel.listItem2')}
              </button>
            </li>
            {!isReplay && (
              <li>
                <button
                  type="button"
                  onClick={() => {
                    closeSideMenu();
                    setIsChatExpanded(true);
                  }}
                >
                  {text.t('topPanel.listItem3')}
                </button>
              </li>
            )}
          </ul>
          <div className={styles.lowerpart}>
            <button
              type="button"
              className={clsx(styles.menuCloseButton, {
                [styles.active]: isSideMenuActive,
              })}
              onClick={closeSideMenu}
            >
              <ArrowHead />
            </button>
            <UserProfileContainer />
            {breakpoint === MOBILE && (
              <div className={styles.settingsContainer}>
                <SettingButtons setSideMenuActive={setSideMenuActive} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

MobileView.propTypes = {
  modalVisibility: func.isRequired,
};

export default MobileView;
