/* eslint-disable import/prefer-default-export */

// Async delay for X ms.
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// Toggle array value.
export const toggleArrayValue = (arrayList, arrayValue) =>
  arrayList.includes(arrayValue)
    ? arrayList.filter((el) => el !== arrayValue)
    : [...arrayList, arrayValue];

// Generate numbers for a bingo card.
export const generateBingoCardMap = (rows = 15, willShuffleNumbers = false) => {
  const cols = 5;
  let data = [];

  for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
    if (!data[rowIndex]) data[rowIndex] = [];

    for (let columnIndex = 0; columnIndex < cols; columnIndex++) {
      data[rowIndex][columnIndex] = {
        Value: columnIndex * rows + rowIndex + 1,
        InUse: true,
      };
    }
  }

  if (willShuffleNumbers) {
    data = data.sort(() => 0.5 - Math.random());
  }

  return data;
};

// Getting a random integer between two values
export const getRandomInt = (min, max) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);

  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (maxInt - minInt) + minInt);
};

export const parseBingoCardsLayouts = (item) => {
  const fullData = [];
  const numbers = JSON.parse(item.layout || item.cardLayout);
  numbers.forEach((field) => {
    const data = [];
    field.map((number) => {
      if (!data[number.y]) {
        data[number.y] = [];
      }
      data[number.y][number.x] = number;
      return number;
    });
    data.map((row) => fullData.push(row));
  });
  // eslint-disable-next-line no-param-reassign
  item.numbersMap = fullData;
  return item;
};
