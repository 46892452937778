/* eslint-disable no-underscore-dangle */
import * as signalR from '@microsoft/signalr';
import useNumbersDrawnStore from 'stores/numbers-drawn';
import useLeaderboardStore from 'stores/leaderboard';
import useUpcomingGameStore from 'stores/upcoming-game';
import useGameStore, {
  BINGO,
  JACKPOT,
  MULTI_JACKPOT,
  ACTIVE_GAME,
} from 'stores/game';
import useCurrentGameStore from 'stores/current-game';
import useWinnerStatsStore from 'stores/winner-stats';
import useUserStore from 'stores/user';
import useGameErrorStore from 'stores/game-error';
import useCardRankingStore from 'stores/card-ranking';
import useApiErrorStore from 'stores/api-error';

export default class SocketManager {
  constructor() {
    if (this._instance) {
      return SocketManager.instance;
    }

    this.numbersDrawnState = useNumbersDrawnStore.getState();
    this.leaderboardState = useLeaderboardStore.getState();
    this.upcomingGameState = useUpcomingGameStore.getState();
    this.gameState = useGameStore.getState();
    this.currentGameState = useCurrentGameStore.getState();
    this.winnerStatsState = useWinnerStatsStore.getState();
    this.userState = useUserStore.getState();
    this.gameError = useGameErrorStore.getState();
    this.cardRankingState = useCardRankingStore.getState();
    this.apiErrorState = useApiErrorStore.getState();

    this.bingoconnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.userState.user.apiURL}/bingohub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 5000, 20000, 50000])
      .build();

    this.bingoconnection.onreconnecting((error) => {
      // message to user, tries to reconnect
      console.log('Tries to reconnect with SignalR: ', error);
      this.apiErrorState.setErrorMessage(7);
    });
    this.bingoconnection.onclose((err) => {
      console.log('disconnect error from SignalR: ', err);
      // Message to user, reconnection failed, reload window
      this.apiErrorState.setErrorMessage(8);
    });
    this.bingoconnection.onreconnected(() => {
      console.log('reconnection was made');
      //  Message to user that connection is back on
      this.apiErrorState.setErrorMessage(9);
      this.bingoconnection.invoke(
        'JoinRoom',
        this.userState.user.room,
        this.userState.user.nickname,
        this.userState.user.access_token
      );
    });

    this.bingoconnection.on('numberDraw', (drawnNumbers) => {
      const lastBall = drawnNumbers.numbers[drawnNumbers.numbers.length - 1];
      this.addNewBall(lastBall, drawnNumbers.numbers);
    });

    this.bingoconnection.on('nextGame', (nextGame) => {
      //  Information about next game
      this.updateUpcomingGame(nextGame);
    });

    this.bingoconnection.on('highScoreList', (highScore) => {
      // List of highscore
      this.updateLeaderboard(highScore.highScorers);
    });

    this.bingoconnection.on('presentPrize', (prize) => {
      // Present prizes after game ended
      this.displayPrizeAnimation(prize);
    });

    this.bingoconnection.on('currentGame', (currentGame) => {
      // Info about current game
      this.updateCurrentGame(currentGame);
    });

    this.bingoconnection.on('roundEnd', (roundEnd) => {
      // Round end presents scoreboard
      this.onRoundEnded(roundEnd);
    });

    this.bingoconnection.on('roundCouldNotStart', (message) => {
      // message when round could not start that explains what is wrong
      this.onGameError(message);
    });

    this.bingoconnection.on('bingoCards', (cardRanking) => {
      // cardranking during game
      this.updateCardRanking(cardRanking);
    });

    // eslint-disable-next-line no-unused-vars
    this.bingoconnection.on('StartRound', (roundId) => {
      this.startBingoRound();
    });

    this.bingoconnection
      .start()
      .then(() => {
        this.bingoconnection.invoke(
          'JoinRoom',
          this.userState.user.room,
          this.userState.user.nickname,
          this.userState.user.access_token
        );
      })
      .catch((err) => console.error(err.toString()));
  }

  static get instance() {
    if (!this._instance) {
      this._instance = new SocketManager();
    }

    return this._instance;
  }

  startBingoRound() {
    this.gameState.setGameState(ACTIVE_GAME);
    this.gameState.setGameStartVO(true);
  }

  updateCurrentGame(currentGame) {
    this.currentGameState.setCurrentGame(currentGame);
  }

  addNewBall(newBall, newList) {
    const currentNumberDrawnState = useNumbersDrawnStore.getState();

    // Should only be short one ball, otherwise set the whole list as value
    if (currentNumberDrawnState.numbers.length === newList.length - 1) {
      this.numbersDrawnState.addNumber(newBall);
    } else {
      this.numbersDrawnState.addNewNumbersList(newList);
    }
  }

  displayPrizeAnimation(prize) {
    let animationType = prize.isJackpot ? JACKPOT : BINGO;
    if (prize.isJackpot && prize.sharedAmountPerCard !== 0) {
      animationType = MULTI_JACKPOT;
    }
    setTimeout(() => {
      this.winnerStatsState.setWinnerList(prize.winners);
      this.gameState.setPrizeNotification(animationType);
    }, 1000);
  }

  onRoundEnded(scoreBoard) {
    this.winnerStatsState.setScoreboardList(scoreBoard);
    this.numbersDrawnState.resetNumbers();
  }

  updateLeaderboard(leaders) {
    this.leaderboardState.setLeaderboard(leaders);
  }

  updateUpcomingGame(nextGame) {
    this.upcomingGameState.setGame(nextGame);
  }

  onGameError() {
    this.gameError.setIsGameErrorVisible(true);
  }

  updateCardRanking(cardRanking) {
    this.cardRankingState.updateCardRanking(cardRanking.cards);
  }
}
