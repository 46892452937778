import React from 'react';
import { bool, func, number } from 'prop-types';

import text from 'polyglot/polyglot';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import styles from './ConfirmPurchase.module.scss';

const ConfirmPurchase = ({
  isLoading,
  onClickConfirm,
  onClose,
  cardPrice,
  cardsCount,
  totalPrice,
}) => (
  <Modal
    isLoading={isLoading}
    onClickOutside={onClose}
    title={text.t('purchaseModal.purchase.title')}
    content={
      <div className={styles.table}>
        <div className={styles.row}>
          <p className={styles.th}>
            {text.t('purchaseModal.purchase.bingoCardPrice')}:
          </p>
          <p className={styles.td}>
            {text.t('purchaseModal.purchase.currencyAppendix', {
              amount: cardPrice,
            })}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.th}>
            {text.t('purchaseModal.purchase.bingoCardsAmount')}:
          </p>
          <p className={styles.td}>
            {text.t('purchaseModal.purchase.countAppendix', {
              total: cardsCount,
            })}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.th}>{text.t('purchaseModal.purchase.total')}:</p>
          <p className={styles.td}>
            {text.t('purchaseModal.purchase.currencyAppendix', {
              amount: totalPrice,
            })}
          </p>
        </div>
      </div>
    }
    buttons={[
      <Button
        key="cancel-button"
        className={styles.button}
        onClick={onClose}
        isSecondary
      >
        {text.t('purchaseModal.purchase.cancelButton')}
      </Button>,
      <Button
        key="confirm-button"
        className={styles.button}
        onClick={onClickConfirm}
        isDisabled={!cardsCount}
      >
        {text.t('purchaseModal.purchase.confirmButton')}
      </Button>,
    ]}
  />
);

ConfirmPurchase.propTypes = {
  isLoading: bool,
  onClickConfirm: func,
  onClose: func,
  cardPrice: number.isRequired,
  cardsCount: number.isRequired,
  totalPrice: number.isRequired,
};

ConfirmPurchase.defaultProps = {
  isLoading: false,
  onClickConfirm: () => {},
  onClose: () => {},
};

export default ConfirmPurchase;
