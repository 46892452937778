import React from 'react';
import { arrayOf, func, shape, string, bool } from 'prop-types';
import { useTransition, animated } from 'react-spring';

import Avatar from 'components/Chat/Avatar/Avatar';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import styles from './UserList.module.scss';

const UserList = ({ users, isOpen, onClick }) => {
  const usersTransition = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
  });

  return usersTransition.map(
    ({ item, key, props }) =>
      item && (
        <animated.div className={styles.wrapper} key={key} style={props}>
          <Scrollbars className={styles.scroll}>
            {users.map(({ uid, name, avatar }) => (
              <button
                key={uid}
                type="button"
                className={styles.item}
                onClick={() => onClick(uid, name)}
              >
                <Avatar avatar={avatar} className={styles.avatar} />
                <span className={styles.name}>{name}</span>
              </button>
            ))}
          </Scrollbars>
        </animated.div>
      )
  );
};

UserList.propTypes = {
  users: arrayOf(
    shape({
      uid: string,
      name: string,
      avatarUrl: string,
      isAdmin: bool,
    })
  ),
  isOpen: bool,
  onClick: func,
};

UserList.defaultProps = {
  users: [],
  isOpen: false,
  onClick: () => {},
};

export default UserList;
