import React from 'react';
import { string, bool } from 'prop-types';
import clsx from 'clsx';

import styles from './BingoSquare.module.scss';

const BingoSquare = ({ type, isDark, isVertical, isGolden }) => {
  const collectFields = () => {
    const innerSquareIndex = [6, 7, 8, 11, 12, 13, 16, 17, 18];
    const onewRowIndex = [10, 11, 12, 13, 14];
    const oneRowVertical = [2, 7, 12, 17, 22];
    const oneRowCross = [0, 6, 12, 18, 24];
    const elements = [];
    for (let i = 0; i < 25; i++) {
      elements.push(
        <div
          key={`square-${i}`}
          className={clsx(styles.field, {
            [styles.isVertical]: isVertical,
            [styles.darker]: isDark,
            [styles.full]: type === 'fullcard',
            [styles.onerow]:
              type === '1rowhor' && i >= 5 && onewRowIndex.includes(i),
            [styles.tworow]: type === '2rowhor' && i < 10,
            [styles.threerow]: type === '3rowhor' && i < 15,
            [styles.fourrow]: type === '4rowhor' && i < 20,
            [styles.square]: type === 'square' && !innerSquareIndex.includes(i),
            [styles.vertical]:
              type === '1rowvert' && oneRowVertical.includes(i),
            [styles.cross]: type === '1rowdiag' && oneRowCross.includes(i),
            [styles.gold]: isGolden,
          })}
        />
      );
    }
    return elements;
  };
  return (
    <div className={clsx(styles.wrapper, { [styles.isVertical]: isVertical })}>
      {collectFields()}
    </div>
  );
};

BingoSquare.propTypes = {
  type: string.isRequired,
  isDark: bool,
  isVertical: bool,
  isGolden: bool,
};

BingoSquare.defaultProps = {
  isDark: false,
  isVertical: false,
  isGolden: false,
};

export default BingoSquare;
