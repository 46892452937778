import React from 'react';
import { string, oneOf, oneOfType, number, bool, func } from 'prop-types';
import clsx from 'clsx';

import {
  MARKING_SYMBOL_OVAL,
  MARKING_SYMBOL_POLYGON,
  MARKING_SYMBOL_RECTANGLE,
  MARKING_SYMBOL_STAR,
  MARKING_SYMBOL_TRIANGLE,
} from 'stores/settings';

import Oval from 'assets/svg/oval-shape.svg';
import Polygon from 'assets/svg/polygon-shape.svg';
import Rectangle from 'assets/svg/rectangle-shape.svg';
import Star from 'assets/svg/star-shape.svg';
import Triangle from 'assets/svg/triangle-shape.svg';

import styles from './NumberBlock.module.scss';

const symbols = {
  [MARKING_SYMBOL_OVAL]: <Oval viewBox="0 0 30 30" />,
  [MARKING_SYMBOL_POLYGON]: <Polygon viewBox="0 0 32 30" />,
  [MARKING_SYMBOL_RECTANGLE]: <Rectangle viewBox="0 0 30 30" />,
  [MARKING_SYMBOL_STAR]: <Star viewBox="0 0 30 28" />,
  [MARKING_SYMBOL_TRIANGLE]: <Triangle viewBox="0 0 30 29" />,
};

const NumberBlock = ({
  value,
  symbol,
  symbolColorId,
  isActive,
  isHistory,
  onClick,
}) => {
  const onClickNumber = () => onClick(value);

  if (!value)
    return (
      <div
        className={clsx(styles.number, {
          [styles.isBlank]: true,
        })}
      />
    );

  return (
    <button
      type="button"
      className={clsx(styles.number, {
        [styles.isActive]: isActive,
        [styles.isHistory]: isHistory,
      })}
      onClick={onClickNumber}
    >
      {!isHistory && (
        <span
          className={styles.symbol}
          style={{ fill: `var(--lot-highlight-color-${symbolColorId})` }}
        >
          {symbols[symbol]}
        </span>
      )}
      <span className={styles.text}>{value}</span>
    </button>
  );
};

NumberBlock.propTypes = {
  value: oneOfType([string, number]),
  symbol: oneOf([
    MARKING_SYMBOL_OVAL,
    MARKING_SYMBOL_POLYGON,
    MARKING_SYMBOL_RECTANGLE,
    MARKING_SYMBOL_TRIANGLE,
    MARKING_SYMBOL_STAR,
  ]),
  symbolColorId: number,
  isActive: bool,
  isHistory: bool,
  onClick: func,
};

NumberBlock.defaultProps = {
  value: null,
  symbol: MARKING_SYMBOL_OVAL,
  symbolColorId: 1,
  isActive: false,
  isHistory: false,
  onClick: func,
};

// Only update if not equal.
const propsAreEqual = (prev, next) => {
  // If it's not active we don't need to update,
  // this is very *important* for performance!
  // Author: @chris.a
  if (!prev.isActive && !next.isActive) {
    return true;
  }

  return (
    prev.isActive === next.isActive &&
    prev.symbol === next.symbol &&
    prev.symbolColorId === next.symbolColorId
  );
};

export default React.memo(NumberBlock, propsAreEqual);
