import Polyglot from 'node-polyglot';

const locale = 'sv';
const phrases = {
  topPanel: {
    listItem1: 'Spelregler & vinstplan',
    listItem2: 'Spelansvar',
    listItem3: 'Chat',
    responsibilitiesButton: 'Spelansvar',
    loginButton: 'Logga in för att spela',
  },
  gameWallet: {
    walletText: 'Spelplånbok:',
    buttonText: 'Sätt in',
    total: '%{amount}kr',
  },
  bingoGames: {
    theme1: 'Stjärnskottet',
    theme2: 'Tornadobingo',
    theme3: 'Zoobaloo',
    theme4: 'Femkronan',
    theme5: 'Himlaspelet',
  },
  gameBanner: {
    largeBannerTitle: 'Tid till nästa spel',
    smallBannerTitle: 'Nästa bingospel',
  },
  gameInfo: {
    price: 'Pris',
    numberOfPlayersText: 'Antal spelare',
    numberOfPlayers: '%{amount} st',
    totalPott: 'Total pott',
    jackpott: 'JACKPOTT',
    withinText: 'Inom',
    withinBalls: '%{amount} bollar',
  },
  settings: {
    heading: 'Inställningar',
    autosort: 'Autosortering',
    automark: 'Autoduttning',
    symbols: 'Dutt-symboler',
    colors: 'Färger',
    sound: 'Ljud',
    announcer: 'Utropare',
    effects: 'Effekter',
    music: 'Musik',
    profileimage: 'Profilbild',
  },
  buyLotteryTicketButtons: {
    heading: 'Köp brickor',
    minusButton: '-',
    plusButton: '+',
    predefinedButtons: '%{amount} st',
    maxButton: 'Max',
    buyButton: 'Köp till nästa ',
    payButton: 'Betala',
    amount: '%{amount} kr',
    totalAmount: 'Totalt %{amount} kr',
  },
  leaderboard: {
    heading: 'Ställning',
  },
  chat: {
    toggleButton: 'Öppna / stäng chatt',
    closeButton: 'Stäng chatt',
    admin: 'Chattvärd:',
    private: 'Privatchatt:',
    modal: {
      startPrivateChat: {
        title: 'Starta privatchat',
        text: 'Vill du starta en privatchat med',
        cancelButton: 'Avbryt',
        confirmButton: 'Bekräfta',
      },
    },
  },
  bingoCard: {
    demo: 'Demo',
    purchase: {
      selectButton: 'Välj bricka',
      isSelected: 'Vald bricka',
      isPurchased: 'Betald bricka',
    },
    numbersLeft: '%{numbers} kvar till bingo!',
  },
  prizeplan: {
    title: '%{name} vinstplan',
    priceText: 'Pris',
    numberOfPlayersText: 'Antal spelare',
    totalPottText: 'Total pott',
    withinBallsText: 'Antal bollar',
    header1: 'Deldragning',
    header2: 'Radmönster',
    header3: 'Vinstandel',
    header4: 'Vinst',
    jackpotFullText: 'JACKPOTT inom %{number} bollar',
    jackpotText1: 'JACKPOTT',
    jackpotText2: 'inom %{number} bollar',
    subtitle: 'Spelregler',
  },
  gamingResponsibility: {
    heading1: 'Vårt arbete med spelansvar',
    preamble:
      'För mycket av det roliga är aldrig bra – och det gäller inte minst för spel. Drömmen om den stora vinsten som ska förändra livet finns hos oss de allra flesta och är en viktig drivkraft i spel.',
    content1:
      'Vår målsättning är att Miljonlotteriet ska ligga i framkant när det gäller spelansvar. Spelansvarsarbete minskar risken för att spelare ska utveckla spelproblem så att spel kan fortsätta vara roligt.',
    heading2: 'Åldersgräns',
    content2:
      'Man måste vara 18 år för att kunna få spela på Miljonlotteriets spel. 18-årsgränsen omfattar allt från prenumerationslotter och spel på internet till butiksförsäljning och försäljning via föreningar. Forskning visar att ju tidigare man börjar spela desto större är risken att man hamnar i ett osunt spelande.',
  },
  winnerListModal: {
    title: 'Spelet är slut',
    table: {
      header1: 'Deldragning',
      header2: 'Inom',
      header3: 'Vinst',
      header4: 'Vinnare',
    },
  },
  prizeNotification: {
    bingo: {
      title: 'Grattis till vinnarna!',
    },
    jackpot: {
      title: 'Grattis till vinnaren!',
    },
  },
  purchaseModal: {
    purchase: {
      title: 'Köpbekräftelse',
      bingoCardPrice: 'Brickpris',
      bingoCardsAmount: 'Antal brickor',
      total: 'Totalt',
      countAppendix: '%{total} st',
      currencyAppendix: '%{amount} kr',
      confirmButton: 'Bekräfta',
      cancelButton: 'Avbryt',
    },
    receipt: {
      title: 'Kvitto',
      goodLuck: 'Lycka till %{name}!',
      returnButton: 'Gå till mina brickor',
    },
    incuffientFunds: {
      title: 'Slut på pengar',
      button: 'Sätt in pengar på spelkonto',
    },
  },
  gameErrorModal: {
    tooFewPlayers: {
      title: 'För få spelare',
      content1:
        'Detta bingospel fick för få spelare för att starta. Dina pengar sätts tillbaka i din spelplånbok.',
      content2: 'Nästa bingoomgång öppnas nu för köp.',
      nextButton: 'Gå till nästa bingo',
      backButton: 'Tillbaka',
    },
  },
  apiErrorModal: {
    title: 'Något har gått fel!',
    content0: '',
    content1:
      'Problem uppstod vid hämtning av användaruppgifter, ladda om sidan för att försöka igen.',
    content2: 'Problem uppstod vid hämtning av användarsaldo.',
    content3: 'Vi lyckades inte hämta dina inställningar.',
    content4: 'Vi lyckades inte spara dina inställningar.',
    content5: 'Inga lotter kunde hämtas, försök igen.',
    content6:
      'Problem uppstod i hämtningen av lotter, ladda om sidan för att försöka igen.',
    content7: 'Problem uppstod med anslutningen, vi försöker återansluta.',
    content8:
      'Återanslutning misslyckades. Ladda om sidan för att ansluta igen.',
    content9: 'Återanslutningen lyckades!',
    content10: 'Ett problem uppstod vid köp, försök igen.',
    content11: 'Du måste logga in för att kunna se återuppspelning av lotten',
    closeButtonText: 'OK',
  },
  currencyFormat: 'SEK',
};

const onMissingKey = (key) => {
  console.warn(`Missing translation for key: "${key}"`);

  return key;
};

const text = new Polyglot({ locale, phrases, onMissingKey });
export default text;
