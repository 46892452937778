import React from 'react';
import { arrayOf, shape, string, object, bool } from 'prop-types';

import useLayoutStore from 'stores/layout';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import LayoutDefault from './LayoutDefault/LayoutDefault';
import LayoutDefaultSmall from './LayoutDefaultSmall/LayoutDefaultSmall';
import LayoutWithFooterScroll from './LayoutWithFooterScroll/LayoutWithFooterScroll';
import LayoutWithFooterScrollSmall from './LayoutWithFooterScrollSmall/LayoutWithFooterScrollSmall';

import styles from './BingoCardsLayout.module.scss';

const layoutSelector = (state) => state.layoutId;
const smallTicketSelector = (state) => state.isSmallTickets;

const BingoCardsLayout = ({ items, isSideMenuOpen }) => {
  const currentLayoutId = useLayoutStore(layoutSelector);
  const isSmallTickets = useLayoutStore(smallTicketSelector);

  const breakpoint = useBreakpoint();

  const isMobile = breakpoint === MOBILE;
  const isLayoutWithFooter = currentLayoutId === 3;

  const renderCurrentLayout = () => {
    if (isMobile) {
      if (isSmallTickets) {
        return (
          <LayoutDefaultSmall items={items} layoutId={isSideMenuOpen ? 0 : 2} />
        );
      }
      return <LayoutDefault items={items} layoutId={isSideMenuOpen ? 0 : 2} />;
    }

    if (isLayoutWithFooter) {
      if (isSmallTickets) {
        return <LayoutWithFooterScrollSmall items={items} />;
      }
      return <LayoutWithFooterScroll items={items} />;
    }

    if (isSmallTickets) {
      return <LayoutDefaultSmall items={items} layoutId={currentLayoutId} />;
    }
    return <LayoutDefault items={items} layoutId={currentLayoutId} />;
  };

  return <div className={styles.wrapper}>{renderCurrentLayout()}</div>;
};

BingoCardsLayout.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbers: arrayOf(arrayOf(object)),
    })
  ),
  isSideMenuOpen: bool,
};

BingoCardsLayout.defaultProps = {
  items: [],
  isSideMenuOpen: false,
};

export default BingoCardsLayout;
