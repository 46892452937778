import React from 'react';
import clsx from 'clsx';
import { string, number as numberPropType, bool } from 'prop-types';
import { useTransition, animated } from 'react-spring';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import getBingoBallColor from 'utils/getBingoBallColor';

import darkBlueBall from 'assets/theme/images/bingoBalls/bingoball-blue-600x600.png';
import greenBall from 'assets/theme/images/bingoBalls/bingoball-green-600x600.png';
import lightBlueBall from 'assets/theme/images/bingoBalls/bingoball-lightblue-600x600.png';
import orangeBall from 'assets/theme/images/bingoBalls/bingoball-orange-600x600.png';
import pinkBall from 'assets/theme/images/bingoBalls/bingoball-pink-600x600.png';

import styles from './BingoBall.module.scss';

const bingoballSrc = [
  pinkBall,
  lightBlueBall,
  darkBlueBall,
  orangeBall,
  greenBall,
];

const BingoBall = ({
  className,
  classNameNumber,
  number,
  isLarge,
  willAnimate,
  isVertical,
  currentIndex,
  isLastBall,
}) => {
  const breakpoint = useBreakpoint();
  let fromTransform;
  let enterTransform;
  let updateTransform;
  const spaceBetween = breakpoint === MOBILE ? 130 : 115;
  const scale = breakpoint === MOBILE ? 'scale(2.25)' : 'scale(2.2)';
  const firstPosition = breakpoint === MOBILE ? '65%' : '40%';
  const distance = breakpoint === MOBILE ? 125 : 100;

  if (isVertical) {
    fromTransform = 'translate3d(-100%, 50%, 0) scale(2)';
    enterTransform = 'translate3d(40%, 50%, 0) scale(2)';
    updateTransform = isLastBall
      ? 'translate3d(40%, 50%, 0) scale(2)'
      : `translate3d(0, ${100 + currentIndex * 130}%, 0) scale(1)`;
  } else if (willAnimate) {
    fromTransform = `translate3d(-100%, 60%, 0) ${scale}`;
    enterTransform = `translate3d(${firstPosition}, 60%, 0) ${scale}`;
    updateTransform = isLastBall
      ? `translate3d(${firstPosition}, 60%, 0) ${scale}`
      : `translate3d(${
          distance + currentIndex * spaceBetween
        }%, 0, 0) scale(1)`;
  } else {
    fromTransform = '';
    enterTransform = '';
    updateTransform = '';
  }

  const ballTransitions = useTransition(currentIndex, null, {
    from: {
      transform: fromTransform,
      opacity: 0,
    },
    enter: {
      transform: enterTransform,
      opacity: 1,
    },
    update: {
      transform: updateTransform,
      opacity: currentIndex === 4 ? 0 : 1,
    },
    keys: (item, index) => index,
  });

  return (
    <>
      {ballTransitions.map(({ props }) => (
        <animated.div
          key={`ball-number-${number}`}
          style={{
            backgroundImage: `url(${
              bingoballSrc[getBingoBallColor(number, true) - 1]
            })`,
            ...props,
          }}
          className={clsx(className, styles.wrapper, {
            [styles.isLarge]: isLarge,
            [styles.willAnimate]: willAnimate,
            [styles.isVertical]: isVertical,
            [styles.isAbsolute]: currentIndex,
          })}
        >
          <span className={clsx(classNameNumber, styles.number)}>{number}</span>
        </animated.div>
      ))}
    </>
  );
};
BingoBall.propTypes = {
  number: numberPropType.isRequired,
  className: string,
  classNameNumber: string,
  isLarge: bool,
  willAnimate: bool,
  isVertical: bool,
  currentIndex: numberPropType,
  isLastBall: bool,
};

BingoBall.defaultProps = {
  className: null,
  classNameNumber: null,
  isLarge: false,
  willAnimate: false,
  isVertical: false,
  currentIndex: null,
  isLastBall: false,
};

export default BingoBall;
