import React from 'react';
import clsx from 'clsx';
import { useTransition, animated } from 'react-spring';
import shallow from 'zustand/shallow';

import { generateBingoCardMap } from 'utils/helpers';
import { useChatStore } from 'stores/chat';
import useHistoryStore from 'stores/history';
import useGameStore, { GAME_REPLAY } from 'stores/game';

import Button from 'components/Button/Button';
import BingoCard from 'components/BingoCard/BingoCard';

import styles from './History.module.scss';

const numbersMap = generateBingoCardMap();

const isChatExpandedSelector = (state) => state.isChatExpanded;
const isVisibleSelector = (state) => [
  state.isHistoryVisible,
  state.setIsHistoryVisible,
];
const gameStateSelector = (state) => state.gameState;

const History = () => {
  const isChatExpanded = useChatStore(isChatExpandedSelector);
  const [isVisible, setIsVisible] = useHistoryStore(isVisibleSelector, shallow);
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;

  const onClickClose = () => setIsVisible(false);

  // We don't want the history bingo card to be rendering in the bg,
  // so we mount and unmount it when it becomes visible.
  const transitionsBingoCard = useTransition(isVisible, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isVisible]: isVisible,
        [styles.isChatExpanded]: isReplay ? false : isChatExpanded,
      })}
    >
      <div className={styles.content}>
        <Button
          className={styles.closeButton}
          isModalCloseButton
          onClick={onClickClose}
        />

        {transitionsBingoCard.map(
          ({ item, key, props }) =>
            item && (
              <animated.div key={key} style={props}>
                <BingoCard
                  id="bingo-card-history"
                  numbersMap={numbersMap}
                  isLarge
                  isHistory
                  isDisabled
                />
              </animated.div>
            )
        )}
      </div>
    </div>
  );
};

export default History;
