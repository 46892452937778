import React from 'react';

import useBreakpoint, { MOBILE, DESKTOP } from 'hooks/useBreakpoint';
import text from 'polyglot/polyglot';
import clsx from 'clsx';
import { func, bool } from 'prop-types';
import useMenuStore from 'stores/menu';
import { useChatStore } from 'stores/chat';
import useGameStore, { ACTIVE_GAME, GAME_REPLAY } from 'stores/game';
import usePrizePlanStore from 'stores/prizeplan-modal';
import useUpcomingGameStore from 'stores/upcoming-game';
import useUserStore from 'stores/user';

import Button from 'components/Button/Button';
import GameWallet from 'components/GameWallet/GameWallet';
import LeaderBoard from 'components/GameInfoPanel/Leaderboard/Leaderboard';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';
import LastDrawnBalls from 'components/GameInfoPanel/LastDrawnBalls/LastDrawnBalls';
import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';

import ChatIcon from 'assets/svg/chat-icon.svg';
import InfoIcon from 'assets/theme/svg/info-dark-background.svg';

import styles from './GameLayout.module.scss';

const upcomingGameSelector = (state) => [state.nextGame.cardPrice];
const isMenuExpandedSelector = (state) => state.isMenuExpanded;
const setIsChatExpandedSelector = (state) => state.setIsChatExpanded;
const setModalVisibilitySelector = (state) => state.setModalVisibility;
const gameStateSelector = (state) => state.gameState;
const userSelector = (state) => state.user;

const GameLayout = ({ setIsPurchaseDuringGame, isSideMenuOpen }) => {
  const isMenuExpanded = useMenuStore(isMenuExpandedSelector);
  const setIsChatExpanded = useChatStore(setIsChatExpandedSelector);
  const setModalVisibility = usePrizePlanStore(setModalVisibilitySelector);
  const gameState = useGameStore(gameStateSelector);
  const [upcomingGamePrice] = useUpcomingGameStore(upcomingGameSelector);
  const user = useUserStore(userSelector);
  const isReplay = gameState === GAME_REPLAY;

  const breakpoint = useBreakpoint();

  const setPurchaseView = () => {
    if (gameState === ACTIVE_GAME) {
      setIsPurchaseDuringGame(true);
    }
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isSideMenuOpen]: isSideMenuOpen,
      })}
    >
      <div
        className={clsx(styles.leftSection, {
          [styles.isSideMenuOpen]: isSideMenuOpen,
        })}
      >
        <LastDrawnBalls />
        <div
          className={clsx(styles.prizes, {
            [styles.isExpandedView]: isMenuExpanded,
            [styles.isSideMenuOpen]: isSideMenuOpen,
          })}
        >
          {!isReplay && (
            <Button
              isIcon
              onClick={() => setModalVisibility()}
              className={styles.infoIcon}
            >
              <img src={InfoIcon} alt="i" />
            </Button>
          )}
          <ListOfPrizes isExpandedView={isMenuExpanded} />
        </div>
      </div>
      <div
        className={clsx(styles.rightSection, {
          [styles.isExpandedView]: isMenuExpanded,
        })}
      >
        {!isReplay && (
          <>
            {breakpoint === MOBILE ? (
              <LeaderBoard leaderboardLength={isMenuExpanded ? 4 : 1} />
            ) : (
              <LeaderBoard leaderboardLength={breakpoint === DESKTOP ? 5 : 4} />
            )}
          </>
        )}
        {!isReplay && (
          <div
            className={clsx(styles.buyButtonContainer, {
              [styles.isExpandedView]: isMenuExpanded,
            })}
          >
            <Button className={styles.buyButton} onClick={setPurchaseView}>
              <span className={styles.bold}>
                {text.t('buyLotteryTicketButtons.buyButton')}
              </span>
              {upcomingGamePrice ? (
                <span className={styles.unBold}>
                  {text.t('buyLotteryTicketButtons.amount', {
                    amount: upcomingGamePrice,
                  })}
                </span>
              ) : (
                ''
              )}
            </Button>
            <UpcomingGameBanner nakedBanner />
          </div>
        )}
        <div className={styles.walletContainer}>
          <div className={styles.gameWallet}>
            {user.isAuthenticated && <GameWallet />}
          </div>
          {!isReplay && (
            <Button
              isIcon
              onClick={() => setIsChatExpanded(true)}
              className={styles.chatButton}
            >
              <ChatIcon />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

GameLayout.propTypes = {
  setIsPurchaseDuringGame: func.isRequired,
  isSideMenuOpen: bool,
};

GameLayout.defaultProps = {
  isSideMenuOpen: false,
};

export default GameLayout;
