import React from 'react';
import { bool, func, number, string } from 'prop-types';

import text from 'polyglot/polyglot';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import styles from './PurchaseReceipt.module.scss';

const PurchaseReceipt = ({
  isLoading,
  onClose,
  cardPrice,
  cardsCount,
  totalPrice,
  username,
}) => (
  <Modal
    isLoading={isLoading}
    onClickOutside={onClose}
    title={text.t('purchaseModal.receipt.title')}
    content={
      <div className={styles.table}>
        <div className={styles.row}>
          <p className={styles.th}>
            {text.t('purchaseModal.purchase.bingoCardPrice')}:
          </p>
          <p className={styles.td}>
            {text.t('purchaseModal.purchase.currencyAppendix', {
              amount: cardPrice,
            })}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.th}>
            {text.t('purchaseModal.purchase.bingoCardsAmount')}:
          </p>
          <p className={styles.td}>
            {text.t('purchaseModal.purchase.countAppendix', {
              total: cardsCount,
            })}
          </p>
        </div>
        <div className={styles.row}>
          <p className={styles.th}>{text.t('purchaseModal.purchase.total')}:</p>
          <p className={styles.td}>
            {text.t('purchaseModal.purchase.currencyAppendix', {
              amount: totalPrice,
            })}
          </p>
        </div>

        <p className={styles.text}>
          {text.t('purchaseModal.receipt.goodLuck', {
            name: username,
          })}
        </p>
      </div>
    }
    buttons={[
      <Button
        key="cancel-button"
        className={styles.button}
        onClick={onClose}
        isTertiary
      >
        {text.t('purchaseModal.receipt.returnButton')}
      </Button>,
    ]}
  />
);

PurchaseReceipt.propTypes = {
  isLoading: bool,
  onClose: func,
  cardPrice: number.isRequired,
  cardsCount: number.isRequired,
  totalPrice: number.isRequired,
  username: string.isRequired,
};

PurchaseReceipt.defaultProps = {
  isLoading: false,
  onClose: () => {},
};

export default PurchaseReceipt;
