import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { bool } from 'prop-types';
import confetti from 'canvas-confetti';

import text from 'polyglot/polyglot';
import useWinnerStatsStore from 'stores/winner-stats';

import SoundManager from 'utils/SoundManager';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import letterDSrc from 'assets/theme/images/bingoPrizeNotification/d.png';
import letterESrc from 'assets/theme/images/bingoPrizeNotification/e.png';
import letterLSrc from 'assets/theme/images/bingoPrizeNotification/l.png';
import letterJSrc from 'assets/theme/images/bingoPrizeNotification/j.png';
import letterASrc from 'assets/theme/images/bingoPrizeNotification/a.png';
import letterCSrc from 'assets/theme/images/bingoPrizeNotification/c.png';
import letterKSrc from 'assets/theme/images/bingoPrizeNotification/k.png';
import letterPSrc from 'assets/theme/images/bingoPrizeNotification/p.png';
import letterOSrc from 'assets/theme/images/bingoPrizeNotification/o.png';
import letterTSrc from 'assets/theme/images/bingoPrizeNotification/t.png';
import letterExcSrc from 'assets/theme/images/bingoPrizeNotification/exclamation-mark.png';

import styles from './JackpotAnimation.module.scss';

const subLetters = [letterDSrc, letterESrc, letterLSrc, letterASrc, letterDSrc];

const letters = [
  letterJSrc,
  letterASrc,
  letterCSrc,
  letterKSrc,
  letterPSrc,
  letterOSrc,
  letterTSrc,
  letterTSrc,
  letterExcSrc,
];

const winnerListSelector = (state) => state.winners;

const JackpotAnimation = ({ isMultiJackpot }) => {
  const tlRef = useRef(null);
  // const confettiRef = useRef(null);
  const wrapperRef = useRef(null);
  const canvasRef = useRef(null);
  const winnerList = useWinnerStatsStore(winnerListSelector);

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  useEffect(() => {
    // Play sound.
    SoundManager.instance.playVO('bingo');

    const confettiApp = confetti.create(canvasRef.current, {
      resize: true,
      useWorker: true,
    });

    const colors = ['#734e2e', '#ab7c53', '#f5e8b4'];

    const duration = 1000;
    const animationEnd = Date.now() + duration;
    const defaults = {
      startVelocity: 30,
      spread: 360,
      ticks: 160,
      colors,
    };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 350 * (timeLeft / duration);

      // since particles fall down, start a bit higher than random
      confettiApp({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });

      confettiApp({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);

    // Clean up.
    return () => {
      clearInterval(interval);
      confettiApp?.reset();
    };
  }, []);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: false,
      repeat: false,
    });

    const wrapper = wrapperRef.current;
    const subLettersList = wrapper.querySelectorAll(
      `.${styles.subLetters} > img`
    );
    const lettersList = wrapper.querySelectorAll(`.${styles.letters} > img`);
    const textBlock = wrapper.querySelector(`.${styles.text}`);

    const hasSubLetters = subLettersList.length > 0;

    if (hasSubLetters) {
      subLettersList.forEach((item, index) => {
        tl.fromTo(
          item,
          {
            scale: 0,
            opacity: 0,
          },
          {
            scale: 1,
            opacity: 1,
            ease: 'elastic.out(1, 0.5)',
            duration: 1,
            delay: 0.1 * index,
          },
          0
        );
      });
    }

    lettersList.forEach((item, index) => {
      tl.fromTo(
        item,
        {
          scale: 0,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          ease: 'elastic.out(1, 0.5)',
          duration: 1.25,
          delay: 0.1 * index,
        },
        hasSubLetters ? 0.25 : 0
      );
    });

    // Floating.
    lettersList.forEach((item, index) => {
      tl.to(
        item,
        {
          y: `+=10px`,
          duration: 3.5,
          yoyo: true,
          repeat: -1,
          ease: 'Sine.easeInOut',
          delay: (0.75 * index) / 2,
        },
        hasSubLetters ? 0.4 : 0.2
      );
    });

    if (hasSubLetters) {
      tl.to(
        subLettersList,
        {
          y: `+=5px`,
          duration: 3,
          yoyo: true,
          repeat: -1,
          ease: 'Sine.easeInOut',
        },
        0.4
      );
    }

    tl.to(textBlock, { autoAlpha: 1 }, 0.75);

    tlRef.current = tl;

    // Clean up.
    return () => tl?.kill();
  }, []);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={styles.header}>
        {isMultiJackpot && (
          <div className={styles.subLetters}>
            {subLetters.map((letterSrc, index) => (
              <img
                src={letterSrc}
                // eslint-disable-next-line react/no-array-index-key
                key={`sub-letter-${letterSrc}-${index}`}
                alt=""
              />
            ))}
          </div>
        )}

        <div className={styles.letters}>
          {letters.map((letterSrc, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <img src={letterSrc} key={`letter-${letterSrc}-${index}`} alt="" />
          ))}
        </div>
      </div>

      <div className={styles.text}>
        <h3 className={styles.title}>
          {text.t('prizeNotification.jackpot.title')}
        </h3>

        <div className={styles.list}>
          <Scrollbars className={styles.scroll}>
            {winnerList.map((winner, index) => (
              <div
                className={styles.item}
                // eslint-disable-next-line react/no-array-index-key
                key={winner.name + winner.amount + index}
              >
                <p className={styles.name}>{winner.name}</p>
                <p className={styles.sum}>
                  {new Intl.NumberFormat('sv-SE', settings).format(
                    winner.amount
                  )}
                </p>
              </div>
            ))}
          </Scrollbars>
        </div>
      </div>

      <canvas className={styles.canvas} ref={canvasRef} />
    </div>
  );
};

JackpotAnimation.propTypes = {
  isMultiJackpot: bool,
};

JackpotAnimation.defaultProps = {
  isMultiJackpot: false,
};

export default JackpotAnimation;
