export const dummyRoom = {
  roomId: 'group_bingo-234',
  icon: 'chat-bubble',
  updateAt: 1612964267,
  isPrivate: false,
  unreadCount: 10,
};

export const dummyRooms = [
  {
    roomId: 'private_user-john-18752_user-paul-67424',
    icon: 'user-picture-1',
    updateAt: 1612964267,
    isPrivate: true,
    unreadCount: 2,
  },
  {
    roomId: 'group_bingo-234',
    icon: 'chat-bubble',
    updateAt: 1612964267,
    isPrivate: false,
    unreadCount: 10,
  },
];

export const dummyUsers = [
  {
    uid: 'user-paul-67424',
    name: 'Paul McCartney',
    avatar: {
      icon: 'user-picture-1',
    },
    isAdmin: false,
  },
  {
    uid: 'user-john-18752',
    name: 'John Lennon',
    avatar: {
      icon: 'user-picture-2',
    },
    isAdmin: false,
  },
];

export default {
  dummyRoom,
  dummyRooms,
  dummyUsers,
};
