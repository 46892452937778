import React from 'react';

import text from 'polyglot/polyglot';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import { getUserProfileImg } from 'utils/getUserProfileImg';

import GameWallet from 'components/GameWallet/GameWallet';

import styles from './UserProfileContainer.module.scss';

const userAvatarSelector = (state) => state.settings.userAvatar;
const userSelector = (state) => state.user;

const UserProfileContainer = () => {
  const user = useUserStore(userSelector);
  const userAvatar = useSettingsStore(userAvatarSelector);
  const userAvatarSrc = getUserProfileImg(userAvatar);

  const loginUser = () => {
    user.callback_login();
  };

  return (
    <div className={styles.wrapper}>
      {!user || !user.isAuthenticated ? (
        <button
          type="button"
          className={styles.loginButton}
          onClick={loginUser}
        >
          {text.t('topPanel.loginButton')}
        </button>
      ) : (
        <div className={styles.userContainer}>
          <img src={userAvatarSrc} alt="" className={styles.userIcon} />
          <div className={styles.userInformation}>
            <span>{user.nickname}</span>
            <GameWallet insideUserProfile />
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileContainer;
