/* eslint-disable react/no-array-index-key */

import React from 'react';
import {
  string,
  oneOfType,
  arrayOf,
  bool,
  func,
  number,
  object,
} from 'prop-types';

import NumberBlock from './NumberBlock/NumberBlock';

import styles from './Numbers.module.scss';

const Numbers = ({
  id,
  numbersMap,
  markedNumbers,
  currentSymbol,
  currentSymbolColorId,
  isHistory,
  onClick,
}) =>
  numbersMap.map((rows, rowIndex) => (
    <div className={styles.row} key={`${id}-row-${rowIndex}`}>
      {rows.map((block, i) => (
        <NumberBlock
          key={`${id}-row-${rowIndex}-number-${block.Value || i + 100}`}
          value={block.InUse ? block.Value : ''}
          symbol={currentSymbol}
          symbolColorId={currentSymbolColorId}
          isActive={markedNumbers.includes(block.Value)}
          isHistory={isHistory}
          onClick={onClick}
        />
      ))}
    </div>
  ));

Numbers.propTypes = {
  id: oneOfType([string, number]).isRequired,
  numbersMap: arrayOf(arrayOf(object)).isRequired,
  onClick: func.isRequired,
  markedNumbers: arrayOf(number),
  currentSymbol: string,
  currentSymbolColorId: number,
  isHistory: bool,
};

Numbers.defaultProps = {
  markedNumbers: [],
  currentSymbol: null,
  currentSymbolColorId: null,
  isHistory: false,
};

// Only update if not equal.
const propsAreEqual = (prev, next) =>
  prev.markedNumbers.length === next.markedNumbers.length &&
  prev.currentSymbol === next.currentSymbol &&
  prev.currentSymbolColorId === next.currentSymbolColorId &&
  prev.isHistory === next.isHistory;

export default React.memo(Numbers, propsAreEqual);
