import React, { forwardRef } from 'react';
import { string, bool, func } from 'prop-types';
import clsx from 'clsx';

import hasEmojiSupport from 'utils/hasEmojiSupport';

import TriangleIcon from 'assets/svg/triangle-shape.svg';

import EmojiKeyboard from './EmojiKeyboard/EmojiKeyboard';

import styles from './InputField.module.scss';

const hasEmojiKeyboard = hasEmojiSupport();

const InputField = forwardRef(
  ({ value, isDisabled, isSending, onChange, onFocus, onSubmit }, ref) => {
    const onSelectEmoji = (e) => {
      const emoji = e.currentTarget.innerText ?? null;
      const prevValue = ref.current.value;

      if (emoji) {
        const event = {
          target: {
            value: `${prevValue}${emoji}`,
          },
        };

        onChange(event);
      }
    };

    return (
      <form className={styles.form} onSubmit={onSubmit}>
        {hasEmojiKeyboard && <EmojiKeyboard onSelect={onSelectEmoji} />}

        <input
          type="text"
          ref={ref}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          className={clsx(styles.input, {
            [styles.isDisabled]: isDisabled,
            [styles.isSending]: isSending,
          })}
          disabled={isDisabled}
        />

        <button
          type="submit"
          className={styles.button}
          disabled={isDisabled || !value}
        >
          <TriangleIcon
            className={styles.icon}
            width={null}
            height={null}
            viewBox="0 0 30 29"
          />
        </button>
      </form>
    );
  }
);

InputField.propTypes = {
  value: string,
  isDisabled: bool,
  isSending: bool,
  onFocus: func,
  onSubmit: func,
  onChange: func,
};

InputField.defaultProps = {
  value: '',
  isDisabled: false,
  isSending: false,
  onFocus: () => {},
  onSubmit: () => {},
  onChange: () => {},
};

const propsAreEqual = (prev, next) =>
  prev.value === next.value &&
  prev.isDisabled === next.isDisabled &&
  prev.isSending === next.isSending;

export default React.memo(InputField, propsAreEqual);
