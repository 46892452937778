import create from 'zustand';

const useGameErrorStore = create((set) => ({
  isGameErrorVisible: false,
  setIsGameErrorVisible: (value) => set(() => ({ isGameErrorVisible: value })),
  toggleIsGameErrorVisible: () =>
    set((state) => ({ isGameErrorVisible: !state.isGameErrorVisible })),
}));

export default useGameErrorStore;
