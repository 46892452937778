/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import shallow from 'zustand/shallow';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import api from 'utils/api';
import { parseBingoCardsLayouts } from 'utils/helpers';

import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import { useChatStore } from 'stores/chat';
import usePurchaseStore from 'stores/purchase';
import useUserStore from 'stores/user';
import useCurrentGameStore from 'stores/current-game';
import useUpcomingGameStore from 'stores/upcoming-game';
import useApiErrorStore from 'stores/api-error';
import useLayoutStore from 'stores/layout';

import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';
import Button from 'components/Button/Button';
import GameWallet from 'components/GameWallet/GameWallet';
import Spinner from 'components/Spinner/Spinner';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';
import PurchaseModal from 'components/PurchaseModal/PurchaseModal';

import ChatIcon from 'assets/svg/chat-icon.svg';
import Cross from 'assets/svg/cross.svg';

import BuyLotteryTicketButtons from './BuyLotteryTicketButtons/BuyLotteryTicketButtons';

import styles from './PurchaseLayout.module.scss';

const setIsChatExpandedSelector = (state) => state.setIsChatExpanded;

const isLoadingSelector = (state) => [state.isLoading, state.setIsLoading];
const bingoCardsListSelector = (state) => [
  state.bingoCardsList,
  state.setBingoCardsList,
];
const userSelector = (state) => [state.user];
const selectedCardIdsSelector = (state) => [
  state.selectedCardIds,
  state.resetSelectedCardIds,
];
const currentGameSelector = (state) => state.currentGame;
const prebuyTicketAmountSelector = (state) => state.prebuyTicketAmount;
const upcomingGameSelector = (state) => state.nextGame;
const apiErrorSelector = (state) => state.setErrorMessage;
const isSmallTicketsSelector = (state) => [
  state.isSmallTickets,
  state.setIsSmallTickets,
];

const PurchaseLotteryTicketsPanel = ({
  setIsPurchaseDuringGame,
  isPurchaseDuringGame,
}) => {
  const mountedRef = useRef(true);

  const breakpoint = useBreakpoint();
  const setIsChatExpanded = useChatStore(setIsChatExpandedSelector);
  const [isLoading, setIsLoading] = usePurchaseStore(
    isLoadingSelector,
    shallow
  );
  const [bingoCardsList, setBingoCardsList, ,] = usePurchaseStore(
    bingoCardsListSelector,
    shallow
  );
  const [user] = useUserStore(userSelector);
  const [selectedTicketIds, resetSelectedCardIds] = usePurchaseStore(
    selectedCardIdsSelector,
    shallow
  );
  const currentGame = useCurrentGameStore(currentGameSelector);
  const nextGame = useUpcomingGameStore(upcomingGameSelector);
  const [hasPurchaseModal, setHasPurchaseModal] = useState(false);
  const prebuyTicketAmount = usePurchaseStore(prebuyTicketAmountSelector);
  const setApiErrorMessage = useApiErrorStore(apiErrorSelector);
  const [isSmallTickets, setIsSmallTickets] = useLayoutStore(
    isSmallTicketsSelector,
    shallow
  );

  const onClickChatButton = () => setIsChatExpanded(true);
  const onClickCloseButton = () => setIsPurchaseDuringGame(false);
  const bingoRoundIdRef = useRef();

  const onClickBuy = () => {
    if (!user.isAuthenticated) return;
    setHasPurchaseModal(true);
  };
  const onClickCloseModal = () => setHasPurchaseModal(false);

  const checkIfTicketIsSmall = (ticket) => {
    if (ticket.length === 5 && !isSmallTickets) {
      setIsSmallTickets(true);
    }
    if (ticket.length > 5 && isSmallTickets) {
      setIsSmallTickets(false);
    }
  };

  useEffect(() => {
    const loadBingoCards = async () => {
      if (
        !bingoCardsList.length ||
        currentGame.bingoRoundId !== bingoRoundIdRef.current
      ) {
        if (!isLoading && currentGame.bingoRoundId) {
          setIsLoading(true);
          await api
            .get(`/api/Bingo/cards/${currentGame.bingoRoundId}`)
            .then((res) => {
              setIsLoading(false);
              if (mountedRef.current && res.length) {
                bingoRoundIdRef.current = currentGame.bingoRoundId;
                setBingoCardsList(
                  res.map((item, index) => {
                    parseBingoCardsLayouts(item);
                    item.canBePurchased = !item.bought;
                    item.isPurchased = item.bought;
                    item.id = item.checksum || item.cardId;
                    item.cardNumber = String(index + 1);
                    return item;
                  })
                );
                checkIfTicketIsSmall(res[0].numbersMap);
                resetSelectedCardIds();
              }
            })
            .catch((err) => {
              if (mountedRef.current) {
                console.log('error on retrieving tickets: ', err);
                setApiErrorMessage(5);
                setIsLoading(false);
              }
            });
        }
      }
    };

    mountedRef.current = true;
    if (mountedRef.current) {
      loadBingoCards();
    }

    // Clean up.
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  if (isLoading) return <Spinner />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.bannerWrapper}>
        <UpcomingGameBanner largeBanner />

        {breakpoint === DESKTOP && (
          <div className={styles.prizeWrapper}>
            <ListOfPrizes purchaseView />
          </div>
        )}
      </div>
      <div className={styles.purchaseButtonsWrapper}>
        <div className={styles.ticketButtonsWrapper}>
          <BuyLotteryTicketButtons isPrebuy={isPurchaseDuringGame} />
        </div>
        <div className={styles.innerWrapper}>
          <Button
            onClick={onClickBuy}
            className={styles.payButton}
            isDisabled={
              isPurchaseDuringGame
                ? !prebuyTicketAmount
                : !selectedTicketIds.length
            }
          >
            <span className={styles.bold}>
              {text.t('buyLotteryTicketButtons.payButton')}
            </span>
            {isPurchaseDuringGame ? (
              <span className={styles.unBold}>
                {text.t('buyLotteryTicketButtons.totalAmount', {
                  amount: prebuyTicketAmount * nextGame.cardPrice,
                })}
              </span>
            ) : (
              <span className={styles.unBold}>
                {text.t('buyLotteryTicketButtons.totalAmount', {
                  amount: currentGame.cardPrice
                    ? currentGame.cardPrice * selectedTicketIds.length
                    : 0,
                })}
              </span>
            )}
          </Button>
          {breakpoint !== DESKTOP && (
            <div
              className={clsx(styles.gameWalletAndChatWrapper, {
                [styles.isNotLoggedIn]: !user.isAuthenticated,
              })}
            >
              {user.isAuthenticated && <GameWallet />}
              <Button isIcon onClick={onClickChatButton}>
                <ChatIcon className={styles.chatIcon} />
              </Button>
            </div>
          )}
        </div>
      </div>
      {isPurchaseDuringGame && (
        <button
          type="button"
          className={styles.closeButton}
          onClick={onClickCloseButton}
        >
          <span className={styles.crossWrapper}>
            <Cross className={styles.crossIcon} />
          </span>
        </button>
      )}

      {hasPurchaseModal && (
        <PurchaseModal
          onClose={onClickCloseModal}
          isPrebuy={isPurchaseDuringGame}
        />
      )}
    </div>
  );
};

PurchaseLotteryTicketsPanel.propTypes = {
  setIsPurchaseDuringGame: func.isRequired,
  isPurchaseDuringGame: bool.isRequired,
};

export default PurchaseLotteryTicketsPanel;
