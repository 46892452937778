import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';
import { bool } from 'prop-types';

import usePurchaseStore from 'stores/purchase';

import BingoCardsLayout from 'components/BingoCardsLayout/BingoCardsLayout';
import Spinner from 'components/Spinner/Spinner';

import ReloadIcon from 'assets/svg/reload.svg';

import styles from './PurchaseBingoCards.module.scss';

const selectedCardIdSelector = (state) => state.selectedCardIds;
const isLoadingSelector = (state) => state.isLoading;
const bingoCardsListSelector = (state) => [
  state.bingoCardsList,
  state.setBingoCardsList,
];

const PurchaseBingoCards = ({ isSideMenuOpen }) => {
  const selectedCardIds = usePurchaseStore(selectedCardIdSelector);
  const isLoading = usePurchaseStore(isLoadingSelector);
  const [bingoCardsList, setBingoCardsList] = usePurchaseStore(
    bingoCardsListSelector,
    shallow
  );

  const mappedCards = useMemo(
    () =>
      bingoCardsList.map((item) => ({
        ...item,
        isSelected: selectedCardIds.includes(item.id),
      })),
    [bingoCardsList, selectedCardIds]
  );

  const onClickReload = async () => {
    setBingoCardsList([]);
  };

  return (
    <div className={styles.wrapper}>
      {isLoading && <Spinner isLarge />}

      {!isLoading && bingoCardsList.length ? (
        <>
          <button
            type="button"
            className={styles.reloadButton}
            onClick={onClickReload}
          >
            <ReloadIcon />
          </button>

          <BingoCardsLayout
            items={mappedCards}
            isSideMenuOpen={isSideMenuOpen}
          />
        </>
      ) : null}
    </div>
  );
};

PurchaseBingoCards.propTypes = {
  isSideMenuOpen: bool.isRequired,
};

export default PurchaseBingoCards;
