import React from 'react';

import text from 'polyglot/polyglot';
import { func } from 'prop-types';

import useGameStore, { GAME_REPLAY } from 'stores/game';

import UpcomingGameBanner from 'components/UpcomingGameBanner/UpcomingGameBanner';
import Button from 'components/Button/Button';
import LayoutButtons from 'components/TopbarPanel/LayoutButtons/LayoutButtons';
import SettingButtons from 'components/TopbarPanel/SettingButtons/SettingButtons';
import UserProfileContainer from 'components/TopbarPanel/UserProfileContainer/UserProfileContainer';

import logo from 'assets/theme/svg/logo-desktop-98x41.svg';

import styles from './DesktopView.module.scss';

const gameStateSelector = (state) => state.gameState;

const DesktopView = ({ modalVisibility }) => {
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;

  return (
    <div className={styles.wrapper}>
      <div className={styles.userWrapper}>
        <UserProfileContainer />
      </div>
      <div className={styles.middleWrapper}>
        <div className={styles.settingsWrapper}>
          <SettingButtons />
        </div>
        <img src={logo} alt="miljonlotteriet logo" className={styles.logo} />
        <Button
          className={styles.responsibilityButton}
          isSecondary
          onClick={modalVisibility}
        >
          <span className={styles.buttonText}>
            {text.t('topPanel.responsibilitiesButton')}
          </span>
        </Button>
        <LayoutButtons />
      </div>
      <div className={styles.gameWrapper}>
        {!isReplay && <UpcomingGameBanner />}
      </div>
    </div>
  );
};

DesktopView.propTypes = {
  modalVisibility: func.isRequired,
};

export default DesktopView;
