import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string, bool } from 'prop-types';

import text from 'polyglot/polyglot';

import { useChatStore } from 'stores/chat';

import Modal from 'components/Modal/Modal';
import Avatar from 'components/Chat/Avatar/Avatar';
import Button from 'components/Button/Button';

import UserIcon from 'assets/svg/user.svg';

import UserList from './UserList/UserList';

import styles from './GroupChatHeader.module.scss';

const GroupChatHeader = ({ users }) => {
  const isExpanded = useChatStore((state) => state.isChatExpanded);

  const [hasUserList, setHasUserList] = useState(false);
  const [hasModal, setHasModal] = useState(false);
  const [requestedUser, setRequestedUser] = useState({});

  const admin = users.filter((u) => !!u.isAdmin).find((u) => u !== undefined);
  const regularUsers = users.filter((u) => !u.isAdmin);
  const userCount = regularUsers.length;
  const hasAdmin = !!admin;

  const onClickUserList = () => setHasUserList(!hasUserList);

  const onClickUser = (userId, userName) => {
    setRequestedUser({
      uid: userId,
      name: userName,
    });

    setHasModal(true);
  };

  const onCloseModal = () => {
    setHasModal(false);
  };

  const modalContent = (
    <p>
      {text.t('chat.modal.startPrivateChat.text')}
      <br />
      <b>{requestedUser.name}</b>?
    </p>
  );

  const modalButtons = [
    <Button
      key="cancel-button"
      className={styles.button}
      onClick={onCloseModal}
      isSecondary
    >
      {text.t('chat.modal.startPrivateChat.cancelButton')}
    </Button>,
    <Button
      key="confirm-button"
      className={styles.button}
      onClick={onCloseModal}
    >
      {text.t('chat.modal.startPrivateChat.confirmButton')}
    </Button>,
  ];

  // Close user list on toggle.
  useEffect(() => {
    if (hasUserList && !isExpanded) {
      setHasUserList(false);
    }
  }, [hasUserList, isExpanded]);

  return (
    <>
      <div className={styles.wrapper}>
        {hasAdmin && (
          <div className={styles.admin}>
            <Avatar avatar="user-picture-24" className={styles.adminAvatar} />

            <div className={styles.adminInfo}>
              <span className={styles.adminLabel}>{text.t('chat.admin')}</span>
              &nbsp;
              <span className={styles.adminName}>{admin.name}</span>
            </div>
          </div>
        )}

        <button
          className={styles.usersButton}
          type="button"
          onClick={onClickUserList}
        >
          <UserIcon className={styles.userIcon} />
          <span className={styles.userCount}>{userCount}</span>
        </button>
      </div>

      {hasModal && (
        <Modal
          title={text.t('chat.modal.startPrivateChat.title')}
          content={modalContent}
          buttons={modalButtons}
          onClickOutside={onCloseModal}
        />
      )}

      <UserList
        users={regularUsers}
        isOpen={hasUserList}
        onClick={onClickUser}
      />
    </>
  );
};

GroupChatHeader.propTypes = {
  users: arrayOf(
    shape({
      uid: string,
      name: string,
      avatarUrl: string,
      isAdmin: bool,
    })
  ),
};

GroupChatHeader.defaultProps = {
  users: [
    {
      uid: 'user-maya-2324',
      name: 'Maya',
      avatar: {
        url: 'https://placekitten.com/26/26',
      },
      isAdmin: true,
    },
    {
      uid: 'user-paul-67424',
      name: 'Paul McCartney',
      avatar: {
        icon: 'user-picture-1',
      },
      isAdmin: false,
    },
    {
      uid: 'user-john-18752',
      name: 'John Lennon',
      avatar: {
        icon: 'user-picture-2',
      },
      isAdmin: false,
    },
  ],
};

export default GroupChatHeader;
