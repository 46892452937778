import React from 'react';
import clsx from 'clsx';
import shallow from 'zustand/shallow';

import useLayoutStore from 'stores/layout';

import LayoutShape1 from 'assets/svg/layout-shape-1.svg';
import LayoutShape2 from 'assets/svg/layout-shape-2.svg';
import LayoutShape3 from 'assets/svg/layout-shape-3.svg';
import LayoutShape4 from 'assets/svg/layout-shape-4.svg';
import LayoutShape5 from 'assets/svg/layout-shape-5.svg';
import LayoutShape6 from 'assets/svg/layout-shape-6.svg';

import styles from './LayoutButtons.module.scss';

const currentLayoutSelector = (state) => [state.layoutId, state.setLayoutId];
const isSmallTicketsSelector = (state) => state.isSmallTickets;

const LayoutButtons = () => {
  const [currentLayoutId, setLayoutId] = useLayoutStore(
    currentLayoutSelector,
    shallow
  );
  const isSmallTickets = useLayoutStore(isSmallTicketsSelector);

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        className={styles.layoutButton}
        onClick={() => setLayoutId(1)}
      >
        {!isSmallTickets ? (
          <LayoutShape1
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 1,
            })}
          />
        ) : (
          <LayoutShape4
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 1,
            })}
          />
        )}
      </button>
      <button
        type="button"
        className={styles.layoutButton}
        onClick={() => setLayoutId(2)}
      >
        {!isSmallTickets ? (
          <LayoutShape2
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 2,
            })}
          />
        ) : (
          <LayoutShape5
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 2,
            })}
          />
        )}
      </button>
      <button
        type="button"
        className={styles.layoutButton}
        onClick={() => setLayoutId(3)}
      >
        {!isSmallTickets ? (
          <LayoutShape3
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 3,
            })}
          />
        ) : (
          <LayoutShape6
            className={clsx(styles.layoutIcon, {
              [styles.active]: currentLayoutId === 3,
            })}
          />
        )}
      </button>
    </div>
  );
};
export default LayoutButtons;
