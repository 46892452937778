import create from 'zustand';

const useUserStore = create((set) => ({
  user: {
    access_token: null,
    refresh_token: null,
    room: '',
    isAuthenticated: false,
    nickname: '',
    userId: null,
    isAdmin: false,
    balance: null,
    callback_login: null,
    callback_deposit: null,
    apiURL: '',
    gameResponsibilityLink: null,
    returnButtonURL: '',
    bingocardId: null,
    callback_purchase: null,
    callback_game_finished: null,
    updateBalance: false,
  },
  setUser: (updatedUser) =>
    set((state) => ({ user: { ...state.user, ...updatedUser } })),
}));

export default useUserStore;
