import React from 'react';
import { func } from 'prop-types';

import useGameStore, { BINGO, JACKPOT, MULTI_JACKPOT } from 'stores/game';

import Modal from 'components/Modal/Modal';

import BingoAnimation from './BingoAnimation/BingoAnimation';
import JackpotAnimation from './JackpotAnimation/JackpotAnimation';

import styles from './BingoPrizeNotification.module.scss';

const prizeNotificationSelector = (state) => state.prizeNotification;

const BingoPrizeNotification = ({ onClose }) => {
  const prizeNotification = useGameStore(prizeNotificationSelector);

  const isBingo = prizeNotification === BINGO;
  const isJackpot = prizeNotification === JACKPOT;
  const isMultiJackpot = prizeNotification === MULTI_JACKPOT;

  return (
    <Modal willFill>
      <div className={styles.wrapper}>
        {isBingo && <BingoAnimation />}
        {isJackpot && <JackpotAnimation />}
        {isMultiJackpot && <JackpotAnimation isMultiJackpot />}
      </div>
      <button
        onClick={onClose}
        type="button"
        className={styles.overlayButton}
        aria-hidden
      />
    </Modal>
  );
};

BingoPrizeNotification.propTypes = {
  onClose: func.isRequired,
};

export default BingoPrizeNotification;
