import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { usePrevious, useClickAway } from 'react-use';

import { useChatStore } from 'stores/chat';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import { dummyRoom, dummyRooms } from 'testdata/chat';
import { delay } from 'utils/helpers';

import Spinner from 'components/Spinner/Spinner';

import ToggleButton from './ToggleButton/ToggleButton';
import GroupChatHeader from './GroupChatHeader/GroupChatHeader';
import PrivateChatHeader from './PrivateChatHeader/PrivateChatHeader';
import MessageList from './MessageList/MessageList';
import RoomList from './RoomList/RoomList';

import styles from './Chat.module.scss';

const Chat = () => {
  const isExpanded = useChatStore((state) => state.isChatExpanded);
  const setIsChatExpanded = useChatStore((state) => state.setIsChatExpanded);

  const breakpoint = useBreakpoint();
  const wasExpanded = usePrevious(isExpanded);
  const innerRef = useRef(null);

  const mountedRef = useRef(true);

  const [currentRoom, setCurrentRoom] = useState(dummyRoom);
  const [rooms] = useState(dummyRooms);
  const [isLoading, setIsLoading] = useState(true);

  const isDesktop = breakpoint === DESKTOP;

  const onToggleMenu = () => setIsChatExpanded(!isExpanded);
  const onClickRoom = (room) => setCurrentRoom(room);

  const onClickOutside = () => {
    if (!isDesktop && isExpanded) {
      setIsChatExpanded(false);
    }
  };

  useClickAway(innerRef, onClickOutside);

  const onClickReturn = () => {
    // eslint-disable-next-line no-alert
    alert('TODO: Leave room, and remove tab.');

    // Get first non private room, to return to.
    const groupRoom = rooms
      .filter((r) => !r.isPrivate)
      .find((r) => r !== undefined);

    if (!groupRoom && !groupRoom.roomId) return;

    setCurrentRoom(groupRoom);
  };

  const { isPrivate } = currentRoom;

  // Auth user.
  useEffect(() => {
    const authenticate = async () => {
      setIsLoading(true);

      await delay(500);

      if (mountedRef.current) {
        setIsLoading(false);
      }
    };

    authenticate();

    // Clean up.
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Load current room.
  useEffect(() => {
    const loadRoomDetails = async () => {
      setIsLoading(true);

      await delay(250);

      if (mountedRef.current) {
        setIsLoading(false);
      }
    };

    loadRoomDetails();
  }, [currentRoom.roomId]);

  // Hide menu on resize.
  useEffect(() => {
    if (wasExpanded && !isDesktop) {
      setIsChatExpanded(false);
    }
  }, [wasExpanded, isDesktop, setIsChatExpanded]);

  return (
    <div
      ref={innerRef}
      className={clsx(styles.wrapper, {
        [styles.isExpanded]: isExpanded,
      })}
    >
      <ToggleButton isActive={isExpanded} onClick={onToggleMenu} />

      {isLoading && <Spinner />}

      <RoomList
        rooms={rooms}
        isVisible={isExpanded}
        currentRoomId={currentRoom?.roomId}
        onClick={onClickRoom}
      />

      {!isLoading && (
        <div className={styles.content}>
          {isPrivate ? (
            <PrivateChatHeader
              currentRoomId={currentRoom?.roomId}
              onClickReturn={onClickReturn}
            />
          ) : (
            <GroupChatHeader />
          )}

          <MessageList currentRoomId={currentRoom?.roomId} />
        </div>
      )}
    </div>
  );
};

export default Chat;
