import React from 'react';
import { string } from 'prop-types';

import styles from './NakedBanner.module.scss';

const NakedBanner = ({ name, minutes, seconds }) => (
  <div className={styles.wrapper}>
    <span>{name}</span>
    {minutes && seconds && <span>{`${minutes}:${seconds}`}</span>}
  </div>
);

NakedBanner.propTypes = {
  name: string.isRequired,
  minutes: string.isRequired,
  seconds: string.isRequired,
};

export default NakedBanner;
