import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';

import text from 'polyglot/polyglot';

import { dummyUsers } from 'testdata/chat';

import Avatar from 'components/Chat/Avatar/Avatar';

import CrossIcon from 'assets/svg/cross.svg';

import styles from './PrivateChatHeader.module.scss';

const PrivateChatHeader = ({ currentRoomId, onClickReturn }) => {
  const [users, setUsers] = useState([]);

  const user = users?.filter((u) => !u.isAdmin).find((u) => u !== undefined);

  const hasUser = !!user;

  // Load users from room.
  useEffect(() => {
    if (currentRoomId) {
      setUsers(dummyUsers);
    }
  }, [currentRoomId]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.user}>
          {hasUser && (
            <>
              <Avatar avatar="user-picture-1" className={styles.userAvatar} />

              <div className={styles.userInfo}>
                <span className={styles.userLabel}>
                  {text.t('chat.private')}
                </span>
                <br />
                <span className={styles.userName}>{user.nickname}</span>
              </div>
            </>
          )}
        </div>

        <button type="button" className={styles.button} onClick={onClickReturn}>
          {text.t('chat.closeButton')}

          <span className={styles.icon}>
            <CrossIcon />
          </span>
        </button>
      </div>
    </>
  );
};

PrivateChatHeader.propTypes = {
  currentRoomId: string,
  onClickReturn: func,
};

PrivateChatHeader.defaultProps = {
  currentRoomId: null,
  onClickReturn: () => {},
};

export default PrivateChatHeader;
