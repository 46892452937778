import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import shallow from 'zustand/shallow';

import useWinnerStatsStore from 'stores/winner-stats';
import useGameStatsStore from 'stores/current-game';
import SoundManager from 'utils/SoundManager';
import useUserStore from 'stores/user';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import BingoSquare from 'components/GameInfoPanel/ListOfPrizes/BingoSquare/BingoSquare';

import styles from './ScoreboardModal.module.scss';

const jackpotWinnersSelector = (state) => [state.scoreboard.jackpotWinners];
const winnersSelector = (state) => [state.scoreboard.winners];
const resetScoreboardSelector = (state) => state.resetScoreboard;
const userSelector = (state) => [state.user, state.setUser];
const currentGameSelector = (state) => state.currentGame;

const ScoreboardModal = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [jackpotWinners] = useWinnerStatsStore(jackpotWinnersSelector);
  const [winners] = useWinnerStatsStore(winnersSelector);
  const resetScoreboard = useWinnerStatsStore(resetScoreboardSelector);
  const [currentUser, setUser] = useUserStore(userSelector, shallow);
  const currentGame = useGameStatsStore(currentGameSelector);

  const onClick = () => {
    setIsHidden(true);
    resetScoreboard();
  };

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  useEffect(() => {
    let balance = 0;
    const currentUserNickname = currentUser.nickname;
    winners.map((item) =>
      item.winners.forEach((user) => {
        if (user.name === currentUserNickname) {
          balance += user.amount;
        }
      })
    );
    jackpotWinners.map((item) =>
      item.winners.forEach((user) => {
        if (user.name === currentUserNickname) {
          balance += user.amount;
        }
      })
    );
    currentUser.callback_game_finished({
      gameId: String(currentGame.bingoRoundId),
      name: currentGame.name,
      ticketPrice: currentGame.cardPrice,
      priceAmount: balance,
    });
    if (balance > 0) {
      setUser({ updateBalance: true });
    }
    SoundManager.instance.playVO('bingoend');

    // Clean up.
    return () => {
      SoundManager.instance.stopVO('bingoend');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isHidden) return null;

  return (
    <Modal>
      <Button
        className={styles.outerButton}
        onClick={onClick}
        isModalCloseButton
      />
      <div className={styles.content}>
        <Button
          className={styles.innerButton}
          onClick={onClick}
          isModalCloseButton
        />

        <Scrollbars className={styles.scrollbars}>
          <div className={styles.inner}>
            <h2 className={styles.title}>{text.t('winnerListModal.title')}</h2>

            <table className={styles.table} cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header1')}
                    </span>
                  </th>
                  <th aria-hidden />
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header2')}
                    </span>
                  </th>
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header3')}
                    </span>
                  </th>
                  <th>
                    <span className={styles.header}>
                      {text.t('winnerListModal.table.header4')}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {winners.map((prizeItem) =>
                  prizeItem.winners.map((user, index) => (
                    <tr
                      key={`winner-row-${user.name + user.amount}`}
                      className={clsx({
                        [styles.isJackpot]: prizeItem.isJackpot,
                        [styles.isFirstRowOfType]: index === 0,
                        [styles.isFullRow]: index !== 0,
                      })}
                    >
                      <td>{prizeItem.prizeTypeName}</td>
                      <td>
                        <span className={styles.icon}>
                          <BingoSquare
                            type={
                              prizeItem.prizeTypeCode === '1rowverthordiag'
                                ? '1rowhor'
                                : prizeItem.prizeTypeCode
                            }
                          />
                        </span>
                      </td>
                      <td>{prizeItem.afterNumberOfBalls}</td>
                      <td>
                        {new Intl.NumberFormat('sv-SE', settings).format(
                          user.amount
                        )}
                      </td>
                      <td>{user.name}</td>
                    </tr>
                  ))
                )}
                {jackpotWinners.map((prizeItem) =>
                  prizeItem.winners.map((user, index) => (
                    <tr
                      key={`winner-row-${user.name + user.amount}`}
                      className={clsx({
                        [styles.isJackpot]: prizeItem.isJackpot,
                        [styles.isFirstRowOfType]: index === 0,
                        [styles.isFullRow]: index !== 0,
                      })}
                    >
                      <td>{prizeItem.prizeTypeName}</td>
                      <td>
                        <span className={styles.icon}>
                          <BingoSquare type={prizeItem.prizeTypeCode} />
                        </span>
                      </td>
                      <td>{prizeItem.afterNumberOfBalls}</td>
                      <td>
                        {new Intl.NumberFormat('sv-SE', settings).format(
                          user.amount
                        )}
                      </td>
                      <td>{user.name}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Scrollbars>
      </div>
    </Modal>
  );
};

export default ScoreboardModal;
