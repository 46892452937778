import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';

import useMenuStore from 'stores/menu';
import useGameStore, { PURCHASE_VIEW } from 'stores/game';
import useGameStatsStore from 'stores/current-game';
import { useChatStore } from 'stores/chat';
import useUserStore from 'stores/user';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import GameInfoBar from './GameInfoBar/GameInfoBar';
import UserBingoCards from './UserBingoCards/UserBingoCards';
import PurchaseBingoCards from './PurchaseBingoCards/PurchaseBingoCards';

import styles from './BingoPanel.module.scss';

const menuExpandedSelector = (state) => state.isMenuExpanded;
const menuOpenSelector = (state) => state.isMenuOpen;
const gameStateSelector = (state) => state.gameState;
const chatSelector = (state) => state.isChatExpanded;
const currentGameSelector = (state) => state.currentGame;
const userBaseUrlSelector = (state) => state.user.apiURL;

const BingoPanel = () => {
  const isMenuExpanded = useMenuStore(menuExpandedSelector);
  const isMenuOpen = useMenuStore(menuOpenSelector);
  const isChatExpanded = useChatStore(chatSelector);
  const gameState = useGameStore(gameStateSelector);
  const currentGame = useGameStatsStore(currentGameSelector);
  const baseURL = useUserStore(userBaseUrlSelector);

  const isPurchase = gameState === PURCHASE_VIEW;
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === MOBILE;
  const isOpenSidemenu = isChatExpanded || isMenuOpen;

  const [background, setBackground] = useState('');
  const [logo, setLogo] = useState('');
  const [currentGameID, setGameID] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (currentGame && currentGame.bingoRoundId !== currentGameID) {
      setGameID(currentGame.bingoRoundId);
      if (mounted) {
        axios
          .get(`${baseURL}/${currentGame.logoBig}`)
          .then((res) => {
            if (mounted) {
              setLogo(res.data);
            }
          })
          .catch((err) => {
            if (mounted) {
              console.log('error on trying to get big logo: ', err);
            }
          });
        axios
          .get(`${baseURL}/${currentGame.backgroundBig}`)
          .then((res) => {
            if (mounted) {
              setBackground(res.data);
            }
          })
          .catch((err) => {
            if (mounted) {
              console.log('error on trying to get big background: ', err);
            }
          });
      }
    }

    // Cleanup
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isMenuExpanded]: isMenuExpanded || isPurchase,
      })}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div
        className={clsx(styles.infoBar, {
          [styles.isHidden]: isMobile && isOpenSidemenu && !isPurchase,
        })}
      >
        <GameInfoBar />
      </div>
      <img
        src={logo}
        alt=""
        className={clsx(styles.logo, {
          [styles.isHidden]: isMobile && isOpenSidemenu && !isPurchase,
        })}
      />

      <div
        className={clsx(styles.cards, {
          [styles.isMenuExpanded]: isMenuExpanded || isPurchase,
          [styles.isSideMenuOpen]: isOpenSidemenu && !isPurchase,
        })}
      >
        {isPurchase ? (
          <PurchaseBingoCards isSideMenuOpen={isMobile && isOpenSidemenu} />
        ) : (
          <UserBingoCards isSideMenuOpen={isMobile && isOpenSidemenu} />
        )}
      </div>
    </div>
  );
};

export default BingoPanel;
