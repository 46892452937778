import create from 'zustand';

import { getBreakpointsConfig, DESKTOP } from 'hooks/useBreakpoint';

const initialBreakpoint = getBreakpointsConfig(window.innerWidth);
const isDesktop = initialBreakpoint === DESKTOP;

export const useChatStore = create((set) => ({
  isChatExpanded: isDesktop,
  setIsChatExpanded: (value) => set(() => ({ isChatExpanded: value })),
}));

// export const useChatUserStore = create((set) => ({
//   chatUser: {
//     uid: 'user-john-18752',
//     name: 'John Lennon',
//     avatar: {
//       icon: 'user-picture-1',
//     },
//     isAdmin: false,
//   },
//   setChatUser: (updatedUser) =>
//     set((state) => ({ chatUser: { ...state.chatUser, ...updatedUser } })),
// }));

export default useChatStore;
