import React, { useState } from 'react';
import clsx from 'clsx';

import shallow from 'zustand/shallow';
import useMenuStore from 'stores/menu';
import useGameStore, { PURCHASE_VIEW } from 'stores/game';
import { useChatStore } from 'stores/chat';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import GameLayout from 'components/GameInfoPanel/GameLayout/GameLayout';
import PurchaseLayout from 'components/GameInfoPanel/PurchaseLayout/PurchaseLayout';

import ArrowHead from 'assets/svg/arrowhead.svg';

import styles from './GameInfoPanel.module.scss';

const chatSelector = (state) => state.isChatExpanded;
const menuOpenSelector = (state) => state.isMenuOpen;
const gameStateSelector = (state) => state.gameState;
const isMenuExpandedSelector = (state) => [
  state.isMenuExpanded,
  state.setIsMenuExpanded,
];

const GameInfoPanel = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useMenuStore(
    isMenuExpandedSelector,
    shallow
  );
  const gameState = useGameStore(gameStateSelector);
  const isChatExpanded = useChatStore(chatSelector);
  const isMenuOpen = useMenuStore(menuOpenSelector);
  const [isPurchaseDuringGame, setIsPurchaseDuringGame] = useState(false);

  const isPurchase = gameState === PURCHASE_VIEW || isPurchaseDuringGame;
  const isOpenSidemenu = isChatExpanded || isMenuOpen;
  const isMobile = useBreakpoint() === MOBILE;

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.isExpanded]: isMenuExpanded || isPurchase,
      })}
    >
      <button
        type="button"
        className={clsx(styles.expandedButton, {
          [styles.isExpanded]: isMenuExpanded,
          [styles.isVisible]: !isPurchase,
        })}
        onClick={() => setIsMenuExpanded(!isMenuExpanded)}
      >
        <ArrowHead />
      </button>
      {isPurchase ? (
        <>
          <PurchaseLayout
            isPurchaseDuringGame={isPurchaseDuringGame}
            setIsPurchaseDuringGame={setIsPurchaseDuringGame}
          />
          <div
            className={clsx(styles.overlay, {
              [styles.isVisible]: isMobile && isOpenSidemenu,
            })}
          />
        </>
      ) : (
        <GameLayout
          setIsPurchaseDuringGame={setIsPurchaseDuringGame}
          isSideMenuOpen={isMobile && isOpenSidemenu}
        />
      )}
    </div>
  );
};

export default GameInfoPanel;
