import React, { useEffect, useState } from 'react';
import { string, number } from 'prop-types';
import SoundManager from 'utils/SoundManager';

import text from 'polyglot/polyglot';

import styles from './SmallerBanner.module.scss';

const SmallerBanner = ({
  background,
  logo,
  name,
  minutes,
  seconds,
  gameId,
}) => {
  const [lastPlayedId, setLastPlayedId] = useState('');

  useEffect(() => {
    if (minutes === '00' && seconds === '30' && lastPlayedId !== gameId) {
      // Play sound
      SoundManager.instance.playVO('startsoon30');
      setLastPlayedId(gameId);
    }

    // Clean up.
    return () => {
      SoundManager.instance.stopVO('startsoon30');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutes, seconds]);

  return (
    <div
      style={{ backgroundImage: `url(${background})` }}
      className={styles.wrapper}
    >
      <img src={`${logo}`} alt="" className={styles.logo} />
      <div className={styles.textWrapper}>
        <span>{text.t('gameBanner.smallBannerTitle')}</span>
        <div className={styles.bold}>
          <span>{name}</span>
          {minutes && seconds && <span>{`${minutes}:${seconds}`}</span>}
        </div>
      </div>
    </div>
  );
};
SmallerBanner.propTypes = {
  background: string.isRequired,
  logo: string.isRequired,
  name: string.isRequired,
  minutes: string.isRequired,
  seconds: string.isRequired,
  gameId: number.isRequired,
};

export default SmallerBanner;
