import React, { useRef, useEffect } from 'react';
import { arrayOf, number as numberPropType } from 'prop-types';
import { gsap } from 'gsap';

import text from 'polyglot/polyglot';
import useWinnerStatsStore from 'stores/winner-stats';

import SoundManager from 'utils/SoundManager';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import BingoBall from 'components/BingoBall/BingoBall';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import letterBSrc from 'assets/theme/images/bingoPrizeNotification/b.png';
import letterISrc from 'assets/theme/images/bingoPrizeNotification/i.png';
import letterNSrc from 'assets/theme/images/bingoPrizeNotification/n.png';
import letterGSrc from 'assets/theme/images/bingoPrizeNotification/g.png';
import letterOSrc from 'assets/theme/images/bingoPrizeNotification/o.png';
import letterExcSrc from 'assets/theme/images/bingoPrizeNotification/exclamation-mark.png';

import styles from './BingoAnimation.module.scss';

const letters = [
  letterBSrc,
  letterISrc,
  letterNSrc,
  letterGSrc,
  letterOSrc,
  letterExcSrc,
];

const winnerListSelector = (state) => state.winners;

const BingoAnimation = ({ numbers }) => {
  const wrapperRef = useRef(null);
  const tlRef = useRef(null);
  const winnerList = useWinnerStatsStore(winnerListSelector);

  const breakpoint = useBreakpoint();

  const isMobile = breakpoint === MOBILE;

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  useEffect(() => {
    const tl = gsap.timeline({
      paused: false,
      repeat: false,
    });

    const wrapper = wrapperRef.current;
    const ballsList = wrapper.querySelectorAll(`.${styles.ballWrapper}`);
    const lettersList = wrapper.querySelectorAll(`.${styles.letters} > img`);
    const textBlock = wrapper.querySelector(`.${styles.text}`);

    const defaultFloat = {
      y: '+=10px',
      duration: 3.5,
      yoyo: true,
      repeat: -1,
      ease: 'Sine.easeInOut',
    };

    // Play sound.
    SoundManager.instance.playVO('bingo');

    // Speed up timeline.
    tl.timeScale(1.1);

    lettersList.forEach((item, index) => {
      tl.fromTo(
        item,
        {
          scale: 0,
          opacity: 0,
        },
        {
          scale: 1,
          opacity: 1,
          ease: 'elastic.out(1, 0.5)',
          duration: 1.25,
          delay: 0.1 * index,
        },
        0
      );
    });

    if (!isMobile) {
      ballsList.forEach((item, index) => {
        tl.fromTo(
          item,
          {
            scale: 0,
            opacity: 0,
          },
          {
            scale: 1,
            opacity: 1,
            ease: 'elastic.out(1, 0.5)',
            duration: 1.25,
            delay: 0.1 * index,
          },
          0.5
        );
      });
    }

    tl.to(textBlock, { autoAlpha: 1 }, isMobile ? 0.5 : 0.75);

    // Floating.
    lettersList.forEach((item, index) => {
      tl.to(
        item,
        {
          ...defaultFloat,
          delay: 0.5 * index,
        },
        0.75
      );
    });

    if (!isMobile) {
      ballsList.forEach((item, index) => {
        tl.to(
          item,
          {
            ...defaultFloat,
            y: '-=5px',
            delay: 0.75 * index,
          },
          0.75
        );
      });
    }

    tlRef.current = tl;

    // Clean up.
    return () => {
      SoundManager.instance.stopVO('bingo');
      tl?.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={styles.header}>
        <div className={styles.numbers}>
          {numbers.map((number) => (
            <div
              key={`bingo-animation-number-${number}`}
              className={styles.ballWrapper}
            >
              <BingoBall
                number={number}
                className={styles.ball}
                classNameNumber={styles.numberText}
                isLarge
              />
            </div>
          ))}
        </div>

        <div className={styles.letters}>
          {letters.map((letterSrc) => (
            <img src={letterSrc} key={letterSrc} alt="" />
          ))}
        </div>
      </div>

      <div className={styles.text}>
        <h3 className={styles.title}>
          {text.t('prizeNotification.bingo.title')}
        </h3>

        <div className={styles.list}>
          <Scrollbars className={styles.scroll}>
            {winnerList.map((winner, index) => (
              <div
                className={styles.item}
                // eslint-disable-next-line react/no-array-index-key
                key={winner.name + winner.amount + index}
              >
                <p className={styles.name}>{winner.name}</p>
                <p className={styles.sum}>
                  {new Intl.NumberFormat('sv-SE', settings).format(
                    winner.amount
                  )}
                </p>
              </div>
            ))}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

BingoAnimation.propTypes = {
  numbers: arrayOf(numberPropType),
};

BingoAnimation.defaultProps = {
  numbers: [39, 50, 14, 68, 26],
};

export default BingoAnimation;
