import React from 'react';
import { arrayOf, shape, number, string, func, bool } from 'prop-types';
import clsx from 'clsx';

import Avatar from 'components/Chat/Avatar/Avatar';

import styles from './RoomList.module.scss';

const RoomList = ({ currentRoomId, rooms, isVisible, onClick }) => (
  <div
    className={clsx(styles.wrapper, {
      [styles.isVisible]: isVisible,
    })}
  >
    {rooms.map(
      (room) =>
        room && (
          <button
            key={room.roomId}
            type="button"
            className={clsx(styles.tab, {
              [styles.isActive]: currentRoomId === room.roomId,
            })}
            onClick={() => onClick(room)}
          >
            <Avatar avatar={room.icon} />

            {room.unreadCount > 0 && (
              <span className={styles.notice}>{room.unreadCount}</span>
            )}
          </button>
        )
    )}
  </div>
);

RoomList.propTypes = {
  currentRoomId: string,
  rooms: arrayOf(
    shape({
      roomId: string,
      icon: string,
      updateAt: number,
      isPrivate: bool,
      unreadCount: number,
    })
  ),
  isVisible: bool,
  onClick: func,
};

RoomList.defaultProps = {
  currentRoomId: null,
  rooms: [],
  isVisible: false,
  onClick: () => {},
};

export default RoomList;
