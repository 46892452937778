/* eslint-disable no-underscore-dangle */
import { Howl } from 'howler';
import spriteData from 'data/soundSprite';
import voSpriteMP3 from 'assets/theme/sound/voSprite.mp3';

export default class SoundManager {
  constructor() {
    if (this._instance) {
      return SoundManager.instance;
    }

    this.voSprite = new Howl({
      src: [voSpriteMP3],
      sprite: spriteData,
    });
    this.isMuted = false;
    this.announcerSound = false;

    return this;
  }

  static get instance() {
    if (!this._instance) {
      this._instance = new SoundManager();
    }

    return this._instance;
  }

  playVO(id) {
    if (!this.isMuted && this.announcerSound) {
      this.voSprite.play(id);
    }
  }

  stopVO(id) {
    this.voSprite.stop(id);
  }

  muteApp(value) {
    this.isMuted = value;
  }

  updateAnnouncerSound(value) {
    this.announcerSound = value;
  }

  startBgSound() {}

  stopBgSound() {}
}
