import React, { useState } from 'react';
import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';

import text from 'polyglot/polyglot';
import clsx from 'clsx';
import useUserStore from 'stores/user';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Scrollbars from 'components/Scrollbars/Scrollbars';
import MobileView from './MobileView/MobileView';
import DesktopView from './DesktopView/DesktopView';

import styles from './TopbarPanel.module.scss';

const userSelector = (state) => state.user.gameResponsibilityLink;
const Topbar = () => {
  const breakpoint = useBreakpoint();
  const [modalVisible, setVisibility] = useState(false);
  const link = useUserStore(userSelector);
  const textObj = 'gamingResponsibility';

  const modalVisibility = () => {
    setVisibility(!modalVisible);
  };

  return (
    <>
      {modalVisible && (
        <Modal>
          <Button
            className={styles.closeButton}
            onClick={modalVisibility}
            isModalCloseButton
          />
          <div className={styles.modalWrapper}>
            <Scrollbars className={styles.scroll}>
              <p className={styles.heading1}>{text.t(`${textObj}.heading1`)}</p>
              <p className={styles.preamble}>{text.t(`${textObj}.preamble`)}</p>
              <p className={styles.content}>{text.t(`${textObj}.content1`)}</p>
              <p className={styles.heading2}>{text.t(`${textObj}.heading2`)}</p>
              <p className={styles.content}>{text.t(`${textObj}.content2`)}</p>
              <div
                className={clsx(styles.heading2, styles.link)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: link,
                }}
              />
            </Scrollbars>
            <div className={styles.gradient} />
          </div>
        </Modal>
      )}
      {breakpoint === DESKTOP ? (
        <DesktopView modalVisibility={modalVisibility} />
      ) : (
        <MobileView modalVisibility={modalVisibility} />
      )}
    </>
  );
};

export default Topbar;
