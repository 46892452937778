/* eslint-disable no-undef */
import './publicPath';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import { hot } from 'react-hot-loader/root';

import App from 'components/App/App';
import ThemeApp from 'components/App/Theme';

require('styles/index.scss');

const viewThemeVariables = false;
const displayApp = viewThemeVariables ? ThemeApp : App;

const renderHotHash = (Component, element, appData) => {
  render(<Component appData={appData} />, element);
};

const initApp = (appData, element) => {
  renderHotHash(hot(displayApp), element, appData);
};

export default {
  init(data) {
    initApp(data, document.getElementById(data.elementId));
  },
};
