import React, { useState, useEffect } from 'react';
import { bool } from 'prop-types';
import axios from 'axios';

import useUpcomingGameStore from 'stores/upcoming-game';
import useCurrentGameStore from 'stores/current-game';
import useUserStore from 'stores/user';

import SmallerBanner from './SmallerBanner/SmallerBanner';
import LargerBanner from './LargerBanner/LargerBanner';
import NakedBanner from './NakedBanner/NakedBanner';

const upcomingGameSelector = (state) => [state.nextGame];
const currentGameSelector = (state) => [state.currentGame];
const userBaseUrlSelector = (state) => state.user.apiURL;

const UpcomingGameBanner = ({ largeBanner, nakedBanner }) => {
  const [startOfGame, setStartOfGame] = useState(null);
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [interval, updateInterval] = useState(null);
  const [clockIsStopped, stopCountdown] = useState(false);
  const [upcomingGame] = useUpcomingGameStore(upcomingGameSelector);
  const [currentGame] = useCurrentGameStore(currentGameSelector);
  const [background, setBackground] = useState('');
  const [logoSmall, setLogoSmall] = useState('');
  const [logoBig, setLogoBig] = useState('');
  const [activeCountdownGame, setActiveGame] = useState(currentGame);
  const baseURL = useUserStore(userBaseUrlSelector);

  const countdown = () => {
    const now = new Date().getTime();
    const timeleft = startOfGame - now;
    if (startOfGame) {
      let remainingHours = String(
        Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      if (remainingHours.length === 1) {
        remainingHours = `0${remainingHours}`;
      }
      let remainingMinutes = String(
        Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
      );
      if (remainingMinutes.length === 1) {
        remainingMinutes = `0${remainingMinutes}`;
      }
      let remainingSeconds = String(
        Math.floor((timeleft % (1000 * 60)) / 1000)
      );
      if (remainingSeconds.length === 1) {
        remainingSeconds = `0${remainingSeconds}`;
      }
      if (now > startOfGame) {
        stopCountdown(true);
      } else {
        setHours(remainingHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    }
  };

  useEffect(() => {
    if (startOfGame) {
      updateInterval(setInterval(countdown, 1000));
      return () => updateInterval(clearInterval(interval));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOfGame]);

  useEffect(() => {
    let mounted = true;
    if (activeCountdownGame) {
      const retrieveImages = async () => {
        if (activeCountdownGame.logoSmall && mounted) {
          axios
            .get(`${baseURL}/${activeCountdownGame.logoSmall}`)
            .then((res) => {
              if (mounted) {
                setLogoSmall(res.data);
              }
            })
            .catch((err) => {
              if (mounted) {
                console.log('error on getting small logo: ', err);
              }
            });
        }

        if (activeCountdownGame.backgroundSmall && mounted) {
          axios
            .get(`${baseURL}/${activeCountdownGame.backgroundSmall}`)
            .then((res) => {
              if (mounted) {
                setBackground(res.data);
              }
            })
            .catch((err) => {
              if (mounted) {
                console.log('error on getting small background: ', err);
              }
            });
        }

        if (activeCountdownGame.logoBig && mounted) {
          axios
            .get(`${baseURL}/${activeCountdownGame.logoBig}`)
            .then((res) => {
              if (mounted) {
                setLogoBig(res.data);
              }
            })
            .catch((err) => {
              if (mounted) {
                console.log('error on getting big logo: ', err);
              }
            });
        }
      };
      retrieveImages();
    }

    // Cleanup
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCountdownGame]);

  useEffect(() => {
    if (currentGame && !currentGame.isDrawing) {
      const millisecondsUntilGameStarts = currentGame.startsInSeconds * 1000;
      setStartOfGame(new Date().getTime() + millisecondsUntilGameStarts);
      if (
        activeCountdownGame &&
        activeCountdownGame.bingoRoundId !== currentGame.bingoRoundId
      ) {
        setActiveGame(currentGame);
      }
    } else if (upcomingGame && upcomingGame.startsInSeconds) {
      const millisecondsUntilGameStarts = upcomingGame.startsInSeconds * 1000;
      setStartOfGame(new Date().getTime() + millisecondsUntilGameStarts);
      if (
        activeCountdownGame &&
        activeCountdownGame.bingoRoundId !== upcomingGame.bingoRoundId
      ) {
        setActiveGame(upcomingGame);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upcomingGame, currentGame]);

  useEffect(() => {
    if (clockIsStopped) {
      updateInterval(clearInterval(interval));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clockIsStopped]);

  if (largeBanner) {
    return (
      <>
        {startOfGame && hours && minutes && seconds && (
          <LargerBanner
            background={background}
            logoSmall={logoSmall}
            logoBig={logoBig}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
          />
        )}
      </>
    );
  }
  if (nakedBanner) {
    return (
      <>
        {startOfGame && minutes && seconds && (
          <NakedBanner
            name={
              activeCountdownGame && activeCountdownGame.name
                ? activeCountdownGame.name
                : ''
            }
            minutes={minutes}
            seconds={seconds}
          />
        )}
      </>
    );
  }
  return (
    <>
      {startOfGame && minutes && seconds && (
        <SmallerBanner
          background={background}
          logo={logoSmall}
          name={
            activeCountdownGame && activeCountdownGame.name
              ? activeCountdownGame.name
              : ''
          }
          minutes={minutes}
          seconds={seconds}
          gameId={currentGame.bingoRoundId || 0}
        />
      )}
    </>
  );
};

UpcomingGameBanner.propTypes = {
  largeBanner: bool,
  nakedBanner: bool,
};

UpcomingGameBanner.defaultProps = {
  largeBanner: false,
  nakedBanner: false,
};

export default UpcomingGameBanner;
