/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import usePrizePlanStore from 'stores/prizeplan-modal';
import useCurrentGameStore from 'stores/current-game';
import useGameStore, { GAME_REPLAY } from 'stores/game';
import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';

import Button from 'components/Button/Button';
import PrizePlan from 'components/PrizePlan/PrizePlan';

import Star from 'assets/svg/gold-star.svg';

import styles from './GameInfoBar.module.scss';

const currentGameSelector = (state) => state.currentGame;
const setModalVisibilitySelector = (state) => state.setModalVisibility;
const gameStateSelector = (state) => state.gameState;

const GameInfoBar = () => {
  const modalIsVisible = usePrizePlanStore((state) => state.modalIsVisible);
  const setModalVisibility = usePrizePlanStore(setModalVisibilitySelector);
  const currentGame = useCurrentGameStore(currentGameSelector);
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === MOBILE;
  const gameState = useGameStore(gameStateSelector);
  const isReplay = gameState === GAME_REPLAY;

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  return (
    <div
      className={styles.wrapper}
      onClick={
        isMobile && !modalIsVisible && !isReplay ? setModalVisibility : () => {}
      }
      role="button"
    >
      {modalIsVisible && <PrizePlan closeModal={setModalVisibility} />}
      <div className={styles.innerWrapper}>
        {!isReplay && (
          <Button isIcon onClick={setModalVisibility}>
            <span className={styles.info}>i</span>
          </Button>
        )}
        <div className={styles.column}>
          <span>{text.t('gameInfo.price')}</span>
          <span className={styles.bold}>{`${
            currentGame.cardPrice ? currentGame.cardPrice : 0
          } kr`}</span>
        </div>
        <div className={styles.column}>
          <span>{text.t('gameInfo.numberOfPlayersText')}</span>
          <span className={styles.bold}>
            {text.t('gameInfo.numberOfPlayers', {
              amount: currentGame.numberOfPlayers
                ? currentGame.numberOfPlayers
                : 0,
            })}
          </span>
        </div>
        <div className={styles.column}>
          <span>{text.t('gameInfo.totalPott')}</span>
          <span className={styles.bold}>
            {currentGame.pot
              ? new Intl.NumberFormat('sv-SE', settings).format(currentGame.pot)
              : '0 kr'}
          </span>
        </div>
        {!isReplay && (
          <div className={styles.column}>
            <Star className={clsx(styles.star, styles.smallest)} />
            <Star className={clsx(styles.star, styles.smaller)} />
            <Star className={styles.star} />
            <span className={styles.gold}>{text.t('gameInfo.jackpott')}</span>
            <span className={clsx(styles.bold, styles.gold)}>
              {currentGame.jackpots.length
                ? new Intl.NumberFormat('sv-SE', settings).format(
                    currentGame.jackpots[0].amount
                  )
                : '0 kr'}
            </span>
          </div>
        )}
        {!isReplay && (
          <div className={styles.column}>
            <span className={styles.gold}>{text.t('gameInfo.withinText')}</span>
            <span className={clsx(styles.bold, styles.gold)}>
              {' '}
              {text.t('gameInfo.withinBalls', {
                amount: currentGame.jackpots.length
                  ? currentGame.jackpots[0].triggerNumberOfDraws
                  : 0,
              })}
            </span>
            <Star className={styles.star} />
            <Star className={clsx(styles.star, styles.smaller)} />
            <Star className={clsx(styles.star, styles.smallest)} />
          </div>
        )}
      </div>
    </div>
  );
};
export default GameInfoBar;
