import React from 'react';
import { number, bool } from 'prop-types';
import clsx from 'clsx';

import useBreakpoint, { TABLET_PORTRAIT } from 'hooks/useBreakpoint';
import useHistoryStore from 'stores/history';
import useGameStatsStore from 'stores/current-game';

import Button from 'components/Button/Button';

import lottoIconLarge from 'assets/theme/svg/bingolott-icon-large.svg';
import lottoIconSmall from 'assets/theme/svg/bingolott-icon-small.svg';

import styles from './TotalBallsDrawn.module.scss';

const totalNumberSelector = (state) => state.currentGame.numberOfBalls;

const TotalBallsDrawn = ({ currentNumber, isWhite }) => {
  const breakpoint = useBreakpoint();
  const toggleIsHistoryVisible = useHistoryStore(
    (state) => state.toggleIsHistoryVisible
  );
  const totalNumber = useGameStatsStore(totalNumberSelector);

  const onClick = () => toggleIsHistoryVisible();

  return (
    <div className={clsx(styles.wrapper, { [styles.isWhite]: isWhite })}>
      <span className={styles.currentNumber}>{currentNumber}</span>/
      <span className={styles.totalNumber}>{totalNumber}</span>
      <Button className={styles.button} onClick={onClick} isIcon>
        <img
          src={breakpoint === TABLET_PORTRAIT ? lottoIconSmall : lottoIconLarge}
          className={styles.icon}
          alt="lotto-icon"
        />
      </Button>
    </div>
  );
};

TotalBallsDrawn.propTypes = {
  currentNumber: number.isRequired,
  isWhite: bool.isRequired,
};

export default TotalBallsDrawn;
