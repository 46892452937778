import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';
import { useTransition, animated } from 'react-spring';
import text from 'polyglot/polyglot';

import useBreakpoint, { DESKTOP } from 'hooks/useBreakpoint';
import useCurrentGameStore from 'stores/current-game';
import { getCssVariable } from 'utils/style-variables';

import BingoSquare from './BingoSquare/BingoSquare';

import styles from './ListOfPrizes.module.scss';

const currentGameSelector = (state) => [state.currentGame.prizes];
const nextPrizeLevelSelector = (state) => state.currentGame.nextPrizeLevel;
const pricepotSelector = (state) => state.currentGame.pot;

const ListOfPrizes = ({ isExpandedView, purchaseView, isVertical }) => {
  const [prizes] = useCurrentGameStore(currentGameSelector);
  const activePrizeLevel = useCurrentGameStore(nextPrizeLevelSelector);
  const pricepot = useCurrentGameStore(pricepotSelector);
  const breakpoint = useBreakpoint();

  const settings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
  };

  // eslint-disable-next-line no-nested-ternary
  const activePrize = purchaseView
    ? null
    : breakpoint === DESKTOP
    ? activePrizeLevel - 1
    : activePrizeLevel;

  const transitions = useTransition(
    prizes.map((item) => {
      let backgroundColor = getCssVariable('--bingo-light-background').trim();
      let color = getCssVariable('--dark-text-color').trim();
      let transform = 'scaleX(1)';

      if (activePrize === item.orderNumber) {
        backgroundColor = getCssVariable('--bingo-dark-background').trim();
        color = getCssVariable('--bingo-light-background').trim();
        transform = 'scaleX(1.12)';
      }

      return {
        ...item,
        color,
        backgroundColor,
        transform,
      };
    }),
    (item) => item.prizeTypeName,
    {
      from: { opacity: 0 },
      leave: { opacity: 0 },
      enter: ({ backgroundColor, color, transform }) => ({
        backgroundColor,
        color,
        opacity: 1,
        transform,
      }),
      update: ({ backgroundColor, color, transform }) => ({
        backgroundColor,
        color,
        transform,
      }),
    }
  );

  return (
    <div className={styles.wrapper}>
      {prizes &&
        transitions.map(({ item, props: { color, ...rest } }) => (
          <div
            key={`prize-${item.prizeTypeCode}-${item.orderNumber}`}
            className={clsx(
              styles.prizeContainer,
              `prize-${item.prizeTypeCode}-${item.orderNumber}`,
              {
                [styles.threeRows]: item.prizeTypeCode === '1rowverthordiag',
                [styles.dark]: activePrize && activePrize === item.orderNumber,
                [styles.isExpandedView]: isExpandedView,
                [styles.isVertical]: isVertical,
              }
            )}
          >
            <animated.span className={styles.background} style={{ ...rest }} />
            <span className={styles.text} style={{ color }}>
              {item.prizeTypeName}
            </span>
            {item.prizeTypeCode === '1rowverthordiag' ? (
              <span className={styles.icon}>
                <BingoSquare
                  type="1rowhor"
                  isDark={activePrize === item.orderNumber}
                  isVertical={isVertical}
                />
                {!isVertical && (
                  <BingoSquare
                    type="1rowvert"
                    isDark={activePrize === item.orderNumber}
                  />
                )}
                {!isVertical && (
                  <BingoSquare
                    type="1rowdiag"
                    isDark={activePrize === item.orderNumber}
                  />
                )}
              </span>
            ) : (
              <span className={styles.icon}>
                <BingoSquare
                  type={item.prizeTypeCode}
                  isDark={activePrize === item.orderNumber}
                  isVertical={isVertical}
                />
              </span>
            )}
            <span className={styles.prize} style={{ color }}>
              {pricepot
                ? new Intl.NumberFormat('sv-SE', settings).format(
                    (pricepot * item.percent) / 100
                  )
                : '0 kr'}
            </span>
          </div>
        ))}
    </div>
  );
};

ListOfPrizes.propTypes = {
  isExpandedView: bool,
  purchaseView: bool,
  isVertical: bool,
};

ListOfPrizes.defaultProps = {
  isExpandedView: false,
  purchaseView: false,
  isVertical: false,
};
export default ListOfPrizes;
