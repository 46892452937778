import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';

import chatIcon from 'assets/theme/svg/userPictures/chat-bubble.svg';
import icon1 from 'assets/theme/svg/userPictures/user-picture-1.svg';
import icon2 from 'assets/theme/svg/userPictures/user-picture-2.svg';
import icon3 from 'assets/theme/svg/userPictures/user-picture-3.svg';
import icon4 from 'assets/theme/svg/userPictures/user-picture-4.svg';
import icon5 from 'assets/theme/svg/userPictures/user-picture-5.svg';
import icon6 from 'assets/theme/svg/userPictures/user-picture-6.svg';
import icon7 from 'assets/theme/svg/userPictures/user-picture-7.svg';
import icon8 from 'assets/theme/svg/userPictures/user-picture-8.svg';
import icon9 from 'assets/theme/svg/userPictures/user-picture-9.svg';
import icon10 from 'assets/theme/svg/userPictures/user-picture-10.svg';
import icon11 from 'assets/theme/svg/userPictures/user-picture-11.svg';
import icon12 from 'assets/theme/svg/userPictures/user-picture-12.svg';
import icon13 from 'assets/theme/svg/userPictures/user-picture-13.svg';
import icon14 from 'assets/theme/svg/userPictures/user-picture-14.svg';
import icon15 from 'assets/theme/svg/userPictures/user-picture-15.svg';
import icon16 from 'assets/theme/svg/userPictures/user-picture-16.svg';
import icon17 from 'assets/theme/svg/userPictures/user-picture-17.svg';
import icon18 from 'assets/theme/svg/userPictures/user-picture-18.svg';
import icon19 from 'assets/theme/svg/userPictures/user-picture-19.svg';
import icon20 from 'assets/theme/svg/userPictures/user-picture-20.svg';
import icon21 from 'assets/theme/svg/userPictures/user-picture-21.svg';
import icon22 from 'assets/theme/svg/userPictures/user-picture-22.svg';
import icon23 from 'assets/theme/svg/userPictures/user-picture-23.svg';
import icon24 from 'assets/theme/svg/userPictures/user-picture-24.svg';

import styles from './Avatar.module.scss';

const icons = {
  'chat-bubble': chatIcon,
  'user-picture-1': icon1,
  'user-picture-2': icon2,
  'user-picture-3': icon3,
  'user-picture-4': icon4,
  'user-picture-5': icon5,
  'user-picture-6': icon6,
  'user-picture-7': icon7,
  'user-picture-8': icon8,
  'user-picture-9': icon9,
  'user-picture-10': icon10,
  'user-picture-11': icon11,
  'user-picture-12': icon12,
  'user-picture-13': icon13,
  'user-picture-14': icon14,
  'user-picture-15': icon15,
  'user-picture-16': icon16,
  'user-picture-17': icon17,
  'user-picture-18': icon18,
  'user-picture-19': icon19,
  'user-picture-20': icon20,
  'user-picture-21': icon21,
  'user-picture-22': icon22,
  'user-picture-23': icon23,
  'user-picture-24': icon24,
};

const Avatar = ({ avatar, className }) => {
  const url = icons[avatar];

  return (
    <div
      className={clsx(styles.wrapper, className, {
        [styles.isChatBubble]: avatar.icon === 'chat-bubble',
      })}
    >
      {url && <img src={url} alt="" className={styles.image} />}
    </div>
  );
};

Avatar.propTypes = {
  avatar: string,
  className: string,
};

Avatar.defaultProps = {
  avatar: {},
  className: null,
};

export default React.memo(Avatar, () => true);
