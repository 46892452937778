import React from 'react';
import { bool } from 'prop-types';
import clsx from 'clsx';

import text from 'polyglot/polyglot';
import useUserStore from 'stores/user';

import Button from 'components/Button/Button';

import styles from './GameWallet.module.scss';

const GameWallet = ({ insideUserProfile }) => {
  const user = useUserStore((state) => state.user);

  const insertMoney = () => {
    user.callback_deposit();
  };

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.column]: !insideUserProfile,
      })}
    >
      <div>{text.t('gameWallet.walletText')}</div>
      <div
        className={clsx(styles.secondContainer, {
          [styles.column]: !insideUserProfile,
        })}
      >
        {user.balance && (
          <span>{`${text.t('gameWallet.total', {
            amount: user.balance,
          })}`}</span>
        )}
        <Button
          onClick={insertMoney}
          isClean
          className={clsx(styles.addMoney, {
            [styles.column]: !insideUserProfile,
          })}
        >
          {text.t('gameWallet.buttonText')}
        </Button>
      </div>
    </div>
  );
};

GameWallet.propTypes = {
  insideUserProfile: bool,
};

GameWallet.defaultProps = {
  insideUserProfile: false,
};

export default GameWallet;
