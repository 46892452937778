import React, { useState, useEffect, useRef } from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateEffect } from 'react-use';

import useUserStore from 'stores/user';

import Avatar from 'components/Chat/Avatar/Avatar';
import Scrollbars from 'components/Scrollbars/Scrollbars';

import InputField from './InputField/InputField';

import styles from './MessageList.module.scss';

const userSelector = (state) => state.user;

const MessageList = ({ currentRoomId }) => {
  const chatUser = useUserStore(userSelector);

  const inputRef = useRef();
  const listRef = useRef();
  const scrollRef = useRef();

  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [isSending, setIsSending] = useState(false);

  const onChange = (e) => setMessageText(e.target.value);

  const onSubmit = async (event) => {
    event.preventDefault();

    if (isSending) return;

    if (!messageText) return;

    // NOTE: Creates a dummy message for testing.
    const dummyMessage = {
      messageId: uuidv4(),
      sentAt: Date.now(),
      roomId: currentRoomId,
      isPrivate: false,
      text: messageText,
      sender: chatUser,
    };

    setIsSending(true);
    setMessageText('');

    // No duplicates.
    const filteredMessages = messages.filter(
      (msg) => msg.messageId !== dummyMessage.messageId
    );

    setMessages([...filteredMessages, dummyMessage]);

    setIsSending(false);

    inputRef.current.focus();
  };

  useEffect(() => {
    if (listRef.current && scrollRef.current && messages.length > 0) {
      scrollRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages.length]);

  // Clear message history, when change of room.
  useUpdateEffect(() => setMessages([]), [currentRoomId]);

  return (
    <div className={styles.wrapper}>
      <Scrollbars className={styles.scroll} ref={scrollRef}>
        <div className={styles.list} ref={listRef}>
          {messages.map(
            ({
              messageId,
              text,
              sender: { userId, nickname, avatar, isAdmin },
            }) => {
              const isMine = chatUser.userId === userId;

              return (
                <div
                  key={messageId}
                  className={clsx(styles.message, {
                    [styles.isAdmin]: isAdmin,
                    [styles.isMine]: isMine,
                  })}
                >
                  <Avatar avatar={avatar} className={styles.avatar} />
                  <p className={styles.text}>
                    <b>{nickname}:</b>
                    &nbsp;
                    {text}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </Scrollbars>

      <InputField
        ref={inputRef}
        value={messageText}
        isDisabled={!chatUser.isAuthenticated}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </div>
  );
};

MessageList.propTypes = {
  currentRoomId: string,
};

MessageList.defaultProps = {
  currentRoomId: null,
};

export default MessageList;
