import React from 'react';
import { string } from 'prop-types';

import useBreakpoint, { MOBILE } from 'hooks/useBreakpoint';
import text from 'polyglot/polyglot';
import usePrizePlanStore from 'stores/prizeplan-modal';

import Button from 'components/Button/Button';

import infoIcon from 'assets/theme/svg/info.svg';

import styles from './LargerBanner.module.scss';

const LargerBanner = ({
  background,
  logoSmall,
  logoBig,
  hours,
  minutes,
  seconds,
}) => {
  const breakpoint = useBreakpoint();
  const smallMobile = breakpoint === MOBILE && window.innerWidth < 374;
  const setModalVisibility = usePrizePlanStore(
    (state) => state.setModalVisibility
  );

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className={styles.imgWrapper}
      >
        <img
          src={smallMobile ? logoSmall : logoBig}
          alt=""
          className={styles.logo}
        />
        <Button
          isIcon
          onClick={setModalVisibility}
          className={styles.infoButton}
        >
          <img src={infoIcon} alt="" className={styles.info} />
        </Button>
      </div>
      <div className={styles.textWrapper}>
        <span className={styles.text}>
          {text.t('gameBanner.largeBannerTitle')}
        </span>
        <span className={styles.time}>
          {hours && minutes && seconds && (
            <span>{`${hours}:${minutes}:${seconds}`}</span>
          )}
        </span>
      </div>
    </div>
  );
};

LargerBanner.propTypes = {
  background: string.isRequired,
  logoSmall: string.isRequired,
  logoBig: string.isRequired,
  hours: string.isRequired,
  minutes: string.isRequired,
  seconds: string.isRequired,
};

export default LargerBanner;
