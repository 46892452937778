import React, { useEffect, useState } from 'react';
import { number, oneOfType, arrayOf, string, bool, object } from 'prop-types';
import clsx from 'clsx';

import text from 'polyglot/polyglot';

import { toggleArrayValue } from 'utils/helpers';
import usePurchaseStore from 'stores/purchase';
import useNumbersDrawnStore from 'stores/numbers-drawn';
import useSettingsStore from 'stores/settings';
import useGameStore, { ACTIVE_GAME, PURCHASE_VIEW } from 'stores/game';
import useCardRankingStore from 'stores/card-ranking';

import Header from './Header/Header';
import Numbers from './Numbers/Numbers';

import styles from './BingoCard.module.scss';

const updateSelectedCardIdsSelector = (state) => state.updateSelectedCardIds;
const numbersDrawnSelector = (state) => state.numbers;
const willAutomarkSelector = (state) => state.settings.automark;
const gameStateSelector = (state) => state.gameState;
const autosortSelector = (state) => state.settings.autosort;
const rankingSelector = (state) => state.cardRanking;

const BingoCard = ({
  id,
  className,
  cardNumber,
  numbersMap,
  footerText,
  currentSymbol,
  currentSymbolColorId,
  isSmall,
  isSmallTinyTicket,
  isMedium,
  isLarge,
  isLargeTinyTicket,
  isDemo,
  canBePurchased,
  isPurchased,
  isDisabled,
  isSelected,
  isHistory,
  hasFooter,
}) => {
  const [markedNumbers, setMarkedNumbers] = useState([]);
  const updateSelectedCardIds = usePurchaseStore(updateSelectedCardIdsSelector);
  const numbersDrawn = useNumbersDrawnStore(numbersDrawnSelector);
  const willAutomark = useSettingsStore(willAutomarkSelector);
  const gameState = useGameStore(gameStateSelector);
  const autosort = useSettingsStore(autosortSelector);
  const ranking = useCardRankingStore(rankingSelector);

  const isActiveGame = gameState === ACTIVE_GAME;
  const isPurchase = gameState === PURCHASE_VIEW;

  const onClickNumber = (value) => {
    setMarkedNumbers((prevState) => toggleArrayValue(prevState, value));
  };

  const onClickCard = () => updateSelectedCardIds(id);

  const renderFooterText = () => {
    if (footerText && autosort) {
      return (
        <p className={styles.footerText}>
          {text.t('bingoCard.numbersLeft', { numbers: footerText })}
        </p>
      );
    }
    if (isPurchased) {
      return (
        <p className={styles.footerText}>
          {text.t('bingoCard.purchase.isPurchased')}
        </p>
      );
    }

    if (canBePurchased) {
      if (isSelected) {
        return (
          <p className={styles.footerText}>
            {text.t('bingoCard.purchase.isSelected')}
          </p>
        );
      }

      return (
        <p className={styles.footerText}>
          {text.t('bingoCard.purchase.selectButton')}
        </p>
      );
    }
    return <></>;
  };

  useEffect(() => {
    // Reset global state when switching from/to ACTIVE_GAME.
    if (isActiveGame || isPurchase) {
      setMarkedNumbers([]);
    }
  }, [isActiveGame, isPurchase, setMarkedNumbers]);

  // Push the numbers to marked numbers for each new number.
  // This creates a cache of numbers if the user choose to switch on and off
  // automark.
  useEffect(() => {
    if ((isHistory || willAutomark) && !isPurchase) {
      // Returns a unique array with Set.
      setMarkedNumbers((prevState) => [
        ...new Set([...prevState, ...numbersDrawn]),
      ]);
    }
  }, [numbersDrawn, isHistory, isPurchase, willAutomark]);

  return (
    <div
      className={clsx(className, styles.wrapper, {
        [styles.isSmall]: isSmall,
        [styles.isSmallTinyTicket]: isSmallTinyTicket,
        [styles.isMedium]: isMedium,
        [styles.isLarge]: isLarge,
        [styles.isLargeTinyTicket]: isLargeTinyTicket,
        [styles.isDisabled]: isDisabled,
        [styles.canBePurchased]: canBePurchased,
        [styles.isSelected]: canBePurchased && isSelected,
        [styles.isPurchased]: isPurchased,
        [styles.isHistory]: isHistory,
      })}
    >
      {canBePurchased && !isPurchased && (
        <button
          type="button"
          onClick={onClickCard}
          className={styles.overlayButton}
          label={
            isSelected
              ? text.t('bingoCard.purchase.isSelected')
              : text.t('bingoCard.purchase.selectButton')
          }
        />
      )}

      {isDemo && (
        <div className={styles.badge}>
          <span className={styles.badgeText}>{text.t('bingoCard.demo')}</span>
        </div>
      )}

      <div className={styles.inner}>
        <Header isHistory={isHistory} />

        <div className={styles.numbers}>
          <Numbers
            id={id}
            numbersMap={numbersMap}
            markedNumbers={markedNumbers}
            currentSymbol={currentSymbol}
            currentSymbolColorId={currentSymbolColorId}
            isHistory={isHistory}
            onClick={onClickNumber}
          />
        </div>

        {hasFooter && (
          <div className={styles.footer}>
            <p className={styles.cardNumber}>
              {!autosort || !ranking.length ? cardNumber : ''}
            </p>

            {renderFooterText()}
          </div>
        )}
      </div>
    </div>
  );
};

BingoCard.propTypes = {
  id: oneOfType([string, number]).isRequired,
  numbersMap: arrayOf(arrayOf(object)).isRequired,
  className: string,
  cardNumber: string,
  footerText: string,
  currentSymbol: string,
  currentSymbolColorId: number,
  isSmall: bool,
  isSmallTinyTicket: bool,
  isMedium: bool,
  isLarge: bool,
  isLargeTinyTicket: bool,
  isDemo: bool,
  canBePurchased: bool,
  isPurchased: bool,
  isDisabled: bool,
  isSelected: bool,
  isHistory: bool,
  hasFooter: bool,
};

BingoCard.defaultProps = {
  className: null,
  cardNumber: '00',
  footerText: null,
  currentSymbol: null,
  currentSymbolColorId: null,
  isSmall: false,
  isSmallTinyTicket: false,
  isMedium: false,
  isLarge: false,
  isLargeTinyTicket: false,
  isDemo: false,
  canBePurchased: false,
  isPurchased: false,
  isDisabled: false,
  isSelected: false,
  isHistory: false,
  hasFooter: false,
};

export default React.memo(BingoCard);
