import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';
import clsx from 'clsx';
import shallow from 'zustand/shallow';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useApiErrorStore from 'stores/api-error';
import SoundManager from 'utils/SoundManager';
import api from 'utils/api';

import Button from 'components/Button/Button';
import SettingsWindow from 'components/SettingsWindow/SettingsWindow';
import Modal from 'components/Modal/Modal';

import ReturnArrow from 'assets/svg/return-arrow.svg';
import Cogwheel from 'assets/svg/settings-cogwheel.svg';
import SoundOn from 'assets/svg/sound-speaker-on.svg';
import SoundOff from 'assets/svg/sound-speaker-off.svg';

import styles from './SettingButtons.module.scss';

const userSelector = (state) => state.user;
const soundSelector = (state) => [state.settings.muted, state.updateSettings];
const announcerSelector = (state) => state.settings.announcer;
const settingsSelector = (state) => state.settings;
const apiErrorSelector = (state) => state.setErrorMessage;

const SettingButtons = ({ isWhite, setSideMenuActive }) => {
  const settings = useSettingsStore(settingsSelector);
  const [isMute, setMute] = useSettingsStore(soundSelector, shallow);
  const announcer = useSettingsStore(announcerSelector);
  const [modalIsVisible, setModalVisibility] = useState(false);
  const user = useUserStore(userSelector);
  const setApiErrorMessage = useApiErrorStore(apiErrorSelector);

  const onClickSetting = () => {
    setSideMenuActive(false);
    setModalVisibility(!modalIsVisible);
  };

  const onClickSound = () => {
    const data = {
      autoSort: settings.autosort,
      autoMark: settings.automark,
      symbol: settings.symbol,
      color: settings.color,
      announcer: settings.announcer ? 'true' : '',
      effects: settings.effects,
      music: settings.music,
      userAvatar: settings.userAvatar,
      muted: !isMute,
    };
    setMute({ muted: !isMute });
    if (user.isAuthenticated) {
      api
        .put('/api/Bingo/clientsetting', data)
        .then(() => {})
        .catch((err) => {
          console.log('err: ', err);
          setApiErrorMessage(4);
        });
    }
  };

  useEffect(() => {
    SoundManager.instance.muteApp(isMute);
  }, [isMute]);

  useEffect(() => {
    SoundManager.instance.updateAnnouncerSound(announcer);
  }, [announcer]);

  return (
    <div className={styles.wrapper}>
      {modalIsVisible && (
        <Modal willJustifyContentLeft willAlignContentBottom>
          <div className={styles.modalWrapper}>
            <SettingsWindow />
            <Button
              isModalCloseButton
              onClick={onClickSetting}
              className={styles.modalCloseButton}
            />
          </div>
        </Modal>
      )}
      <a href={user.returnButtonURL}>
        <Button isIcon onClick={() => {}}>
          <ReturnArrow
            className={clsx(styles.icon, styles.arrow, {
              [styles.white]: isWhite,
            })}
          />
        </Button>
      </a>
      {user.isAuthenticated && (
        <Button isIcon onClick={onClickSetting}>
          <Cogwheel
            className={clsx(styles.icon, {
              [styles.white]: isWhite,
            })}
          />
        </Button>
      )}
      <Button isIcon onClick={onClickSound}>
        {isMute ? (
          <SoundOff
            className={clsx(styles.icon, {
              [styles.white]: isWhite,
            })}
          />
        ) : (
          <SoundOn
            className={clsx(styles.icon, {
              [styles.white]: isWhite,
            })}
          />
        )}
      </Button>
    </div>
  );
};

SettingButtons.propTypes = {
  isWhite: bool,
  setSideMenuActive: func,
};

SettingButtons.defaultProps = {
  isWhite: false,
  setSideMenuActive: () => {},
};

export default SettingButtons;
