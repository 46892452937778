import React from 'react';
import { bool, func } from 'prop-types';

import text from 'polyglot/polyglot';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import styles from './IncuffientFunds.module.scss';

const IncuffientFunds = ({ isLoading, onClickLoadMoney, onClose }) => (
  <Modal
    isLoading={isLoading}
    onClickOutside={onClose}
    title={text.t('purchaseModal.incuffientFunds.title')}
    content={
      <div className={styles.text}>
        {/* @NOTE: This text should come from the backend for more flexible code? */}
        <p>Du har tyvärr inte tillräckligt med pengar i din spelplånbok.</p>
        <p>Fyll på så att du kan fortsätta!</p>
      </div>
    }
    buttons={[
      <Button
        key="confirm-button"
        className={styles.button}
        onClick={onClickLoadMoney}
        isTertiary
      >
        {text.t('purchaseModal.incuffientFunds.button')}
      </Button>,
    ]}
  />
);

IncuffientFunds.propTypes = {
  isLoading: bool,
  onClickLoadMoney: func,
  onClose: func,
};

IncuffientFunds.defaultProps = {
  isLoading: false,
  onClickLoadMoney: () => {},
  onClose: () => {},
};

export default IncuffientFunds;
