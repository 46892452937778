import React from 'react';
import clsx from 'clsx';
import { arrayOf, shape, string, number, object } from 'prop-types';

import useGameStore, { ACTIVE_GAME } from 'stores/game';

import DragScroll from 'components/DragScroll/DragScroll';
import BingoCard from 'components/BingoCard/BingoCard';
import LastDrawnBalls from 'components/GameInfoPanel/LastDrawnBalls/LastDrawnBalls';
import ListOfPrizes from 'components/GameInfoPanel/ListOfPrizes/ListOfPrizes';

import styles from './LayoutDefaultSmall.module.scss';

const gameStateSelector = (state) => state.gameState;

const LayoutDefaultSmall = ({ items, layoutId }) => {
  const [firstCard, secondCard, ...restCards] = items;
  const gameState = useGameStore(gameStateSelector);

  return (
    <>
      <div
        className={clsx(styles.miniCard, {
          [styles.isVisible]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
      >
        {firstCard && (
          <span className={styles.card}>
            <BingoCard
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...firstCard}
              hasFooter
            />
          </span>
        )}
        <LastDrawnBalls isVertical />
        <div className={styles.prizes}>
          <ListOfPrizes isVertical />
        </div>
      </div>
      <DragScroll
        classNameScrollbar={clsx(styles.scrollbar, {
          [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
        })}
        forceUpdate={layoutId}
      >
        <div
          className={clsx(styles.wrapper, {
            [styles.isHidden]: layoutId === 0 && gameState === ACTIVE_GAME,
          })}
        >
          <div className={styles.firstRow}>
            {firstCard && (
              <div key={firstCard.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...firstCard}
                  isLargeTinyTicket
                  hasFooter
                />
              </div>
            )}
            {secondCard && (
              <div key={secondCard.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...secondCard}
                  isLargeTinyTicket
                  hasFooter
                />
              </div>
            )}
          </div>
          <div
            className={clsx(styles.row, {
              [styles.isGrid]: layoutId === 1,
              [styles.isList]: layoutId === 2,
            })}
          >
            {restCards.map((card) => (
              <div key={card.id} className={styles.item}>
                <BingoCard
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...card}
                  isSmallTinyTicket={layoutId === 1}
                  isLargeTinyTicket={layoutId === 2}
                  hasFooter
                />
              </div>
            ))}
          </div>
        </div>
      </DragScroll>
    </>
  );
};

LayoutDefaultSmall.propTypes = {
  items: arrayOf(
    shape({
      id: string.isRequired,
      numbersMap: arrayOf(arrayOf(object)),
    })
  ),
  layoutId: number,
};

LayoutDefaultSmall.defaultProps = {
  items: [],
  layoutId: 1,
};

export default LayoutDefaultSmall;
