/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import shallow from 'zustand/shallow';

import { object } from 'prop-types';
import api from 'utils/api';
import useUserStore from 'stores/user';
import useSettingsStore from 'stores/settings';
import useWinnerStatsStore from 'stores/winner-stats';
import useNumbersDrawnStore from 'stores/numbers-drawn';
import useApiErrorStore from 'stores/api-error';
import useGameStore, {
  NONE,
  ACTIVE_GAME,
  PURCHASE_VIEW,
  GAME_REPLAY,
} from 'stores/game';
import useCurrentGameStore from 'stores/current-game';
import useGameErrorStore from 'stores/game-error';
import SocketManager from 'socket/SocketManager';
import SoundManager from 'utils/SoundManager';

import TopbarPanel from 'components/TopbarPanel/TopbarPanel';
import BingoPanel from 'components/BingoPanel/BingoPanel';
import GameInfoPanel from 'components/GameInfoPanel/GameInfoPanel';
import Chat from 'components/Chat/Chat';
import History from 'components/History/History';
import Spinner from 'components/Spinner/Spinner';
import ScoreboardModal from 'components/ScoreboardModal/ScoreboardModal';
import BingoPrizeNotification from 'components/BingoPrizeNotification/BingoPrizeNotification';
import GameErrorModal from 'components/GameErrorModal/GameErrorModal';
import ApiErrorModal from 'components/ApiErrorModal/ApiErrorModal';

// import DevMenu from 'components/DevMenu/DevMenu';

import styles from './App.module.scss';

//  const isDevelop = true; process.env.NODE_ENV === 'development';

const userSelector = (state) => [state.user, state.setUser];
const gameStateSelector = (state) => [state.gameState, state.setGameState];
const numbersDrawnSelector = (state) => [state.numbers];
const prizeNotificationSelector = (state) => state.prizeNotification;
const currentGameSelector = (state) => state.currentGame;
const gameErrorSelector = (state) => state.isGameErrorVisible;
const apiErrorSelector = (state) => state.isModalVisible;
const setApiError = (state) => state.setErrorMessage;
const resetSettingsSelector = (state) => state.resetSettings;
const resetPrizeNotificationSelector = (state) => state.resetPrizeNotification;
const resetWinnersSelector = (state) => state.resetWinners;
const scoreboardSelector = (state) => state.scoreboard;
const gameStartVoSelector = (state) => [
  state.gameStartVO,
  state.setGameStartVO,
];

const App = ({ appData }) => {
  const mountedRef = useRef(true);
  const socketManager = useRef();

  const [user, setUser] = useUserStore(userSelector, shallow);
  const [gameState, setGameState] = useGameStore(gameStateSelector, shallow);
  const [numbersDrawn] = useNumbersDrawnStore(numbersDrawnSelector);
  const prizeNotification = useGameStore(prizeNotificationSelector);
  const gameError = useGameErrorStore(gameErrorSelector);
  const apiError = useApiErrorStore(apiErrorSelector);
  const setApiErrorMessage = useApiErrorStore(setApiError);
  const currentGame = useCurrentGameStore(currentGameSelector);
  const resetSettings = useSettingsStore(resetSettingsSelector);
  const resetPrizeNotification = useGameStore(resetPrizeNotificationSelector);
  const resetWinners = useWinnerStatsStore(resetWinnersSelector);
  const scoreboard = useWinnerStatsStore(scoreboardSelector);
  const [gameStartVO, setGameStartVO] = useGameStore(
    gameStartVoSelector,
    shallow
  );

  const hasPrizeNotification = prizeNotification !== NONE;
  const hasGameError = gameError;
  const isReplay = gameState === GAME_REPLAY;

  const [isLoading, setIsLoading] = useState(true);

  const collectUserSettings = async () => {
    await api
      .get('/api/Bingo/clientsetting')
      .then((res) => {
        const {
          autoSort,
          autoMark,
          symbol,
          color,
          announcer,
          effects,
          music,
          userAvatar,
          muted,
        } = res;
        const settings = {
          autosort: autoSort,
          automark: autoMark,
          symbol,
          color,
          announcer: announcer === 'true',
          effects,
          music,
          userAvatar,
          muted,
        };

        resetSettings(settings);
      })
      .catch((err) => {
        if (err.statusText !== 'Not Found') {
          console.log('error on getting settings: ', err);
          setApiErrorMessage(3);
        }
      });
  };

  const collectUserBalance = async () => {
    await api
      .get('/api/CustomerTransaction/CombinedBalance')
      .then((res) => {
        setUser({
          balance: res.totalValue,
        });
      })
      .catch((err) => {
        console.log('error on getting balance: ', err);
        setApiErrorMessage(2);
      });
  };

  const collectUserData = async () => {
    await api
      .get('/api/Customer')
      .then((res) => {
        const { userId, nickname } = res;
        setUser({
          isAuthenticated: true,
          nickname,
          userId,
        });
        collectUserBalance();
        collectUserSettings();
      })
      .catch((err) => {
        console.log('error on getting user: ', err);
        setApiErrorMessage(1);
      });
  };

  useEffect(() => {
    if (user.updateBalance) {
      collectUserBalance();
      setUser({ updateBalance: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (gameError && user.isAuthenticated) {
      collectUserBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameError]);

  useEffect(() => {
    if (gameStartVO) {
      // Play sound
      SoundManager.instance.playVO('bingostart');
      setGameStartVO(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStartVO]);

  useEffect(() => {
    if (currentGame.bingoRoundId && isLoading) {
      setIsLoading(false);
    }
    if (currentGame.isDrawing && gameState !== ACTIVE_GAME) {
      setGameState(ACTIVE_GAME);
    }
    if (!currentGame.isDrawing && gameState === ACTIVE_GAME) {
      setGameState(PURCHASE_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGame]);

  useEffect(() => {
    if (hasGameError && gameState !== PURCHASE_VIEW) {
      setGameState(PURCHASE_VIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGameError]);

  useEffect(() => {
    console.log('Appdata: ', appData);

    const updateBaseUrl = async (url) => {
      await api.setBaseURL(url);
    };
    const {
      gameResponsibilityLink,
      callback_deposit,
      callback_login,
      apiURL,
      room,
      returnButtonURL,
      bingocardId,
      callback_purchase,
      callback_game_finished,
    } = appData;
    updateBaseUrl(apiURL);
    if (appData.tokenData) {
      const { access_token, refresh_token } = appData.tokenData;
      setUser({
        access_token,
        refresh_token,
        isAuthenticated: true,
        room,
        apiURL,
        callback_login,
        callback_deposit,
        gameResponsibilityLink,
        returnButtonURL,
        bingocardId,
        callback_purchase,
        callback_game_finished,
      });
      collectUserData();
    } else {
      setUser({
        room,
        apiURL,
        callback_login,
        callback_deposit,
        gameResponsibilityLink,
        returnButtonURL,
        bingocardId,
        callback_purchase,
        callback_game_finished,
      });
    }

    return () => {
      mountedRef.current = false;
      socketManager.current = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.bingocardId) {
      if (user.access_token && user.nickname) {
        if (!socketManager.current) {
          socketManager.current = SocketManager.instance;
        }
      }
      if (user.room && !user.access_token) {
        if (!socketManager.current) {
          socketManager.current = SocketManager.instance;
        }
      }
    }
    if (user.bingocardId && !user.access_token) {
      setIsLoading(false);
      setApiErrorMessage(11);
    }
    if (user.bingocardId && user.access_token) {
      SoundManager.instance.muteApp(true);
      setGameState(GAME_REPLAY);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const closePrizeNotification = () => {
    resetPrizeNotification();
    resetWinners();
  };

  useEffect(() => {
    if (hasPrizeNotification || (hasPrizeNotification && scoreboard)) {
      closePrizeNotification();
    }
    if (numbersDrawn.length) {
      // Play sound
      SoundManager.instance.playVO(
        String(numbersDrawn[numbersDrawn.length - 1])
      );
      // Clean up.
      return () => {
        SoundManager.instance.stopVO(
          String(numbersDrawn[numbersDrawn.length - 1])
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numbersDrawn, scoreboard]);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <Spinner isLarge />
      ) : (
        <div className={styles.innerWrapper}>
          <TopbarPanel />
          <BingoPanel />
          <GameInfoPanel />
          <History />
          {!isReplay && <Chat />}
          {hasPrizeNotification && (
            <BingoPrizeNotification onClose={closePrizeNotification} />
          )}
          {scoreboard && <ScoreboardModal />}
          {hasGameError && <GameErrorModal />}
          {apiError && <ApiErrorModal />}
          {/* {isDevelop && <DevMenu />} */}
        </div>
      )}
      <div id="modal-root" />
    </div>
  );
};

App.propTypes = {
  appData: object,
};

App.defaultProps = {
  appData: {},
};

export default App;
