import create from 'zustand';

import { toggleArrayValue } from 'utils/helpers';

const usePurchaseStore = create((set) => ({
  isLoading: false,
  bingoCardsList: [],
  selectedCardIds: [],
  prebuyTicketAmount: 0,
  setIsLoading: (isLoading) =>
    set(() => ({
      isLoading,
    })),
  setBingoCardsList: (cards) =>
    set(() => ({
      bingoCardsList: cards,
    })),
  resetSelectedCardIds: () => set({ selectedCardIds: [] }),
  updateSelectedCardIds: (cardId) =>
    set((state) => ({
      selectedCardIds: toggleArrayValue(state.selectedCardIds, cardId),
    })),
  updatePrebuyTicketAmount: (newNumber) =>
    set({ prebuyTicketAmount: newNumber }),
}));

export default usePurchaseStore;
