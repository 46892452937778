import React, { useRef, useState } from 'react';
import { func } from 'prop-types';
import { useTransition, animated } from 'react-spring';
import { useClickAway } from 'react-use';

import { emojiList } from 'data/emojis';

import useUserStore from 'stores/user';

import Scrollbars from 'components/Scrollbars/Scrollbars';

import styles from './EmojiKeyboard.module.scss';

const userSelector = (state) => state.user;

const EmojiKeyboard = ({ onSelect }) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const chatUser = useUserStore(userSelector);
  const firstEmoji = emojiList[0] ?? '🙂';
  const listTransition = useTransition(isOpen, null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(0, 8px, 0)',
    },
  });

  const onToggleList = () => setIsOpen(!isOpen);

  const onClickOutside = () => setIsOpen(false);

  useClickAway(wrapperRef, onClickOutside);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {listTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div className={styles.emojis} key={key} style={props}>
              <Scrollbars className={styles.scroll}>
                <div className={styles.list}>
                  {emojiList.map((emoji) => (
                    <button
                      key={`emoji-${emoji}`}
                      className={styles.emoji}
                      type="button"
                      onClick={onSelect}
                      disabled={!chatUser.isAuthenticated}
                    >
                      {emoji}
                    </button>
                  ))}
                </div>
              </Scrollbars>
            </animated.div>
          )
      )}
      <button
        type="button"
        className={styles.button}
        disabled={!chatUser.isAuthenticated}
        onClick={onToggleList}
      >
        {firstEmoji}
      </button>
    </div>
  );
};

EmojiKeyboard.propTypes = {
  onSelect: func,
};

EmojiKeyboard.defaultProps = {
  onSelect: () => {},
};

export default React.memo(EmojiKeyboard, () => true);
